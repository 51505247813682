import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  getNoticeList,
  deleteNotice,
  getNoticeMain,
} from '../../network/api/NoticeAPI';
import Paging from '../util/Paging';
import styles from './Notice.module.css';
import NoticeRegisterDialog from './NoticeRegisterDialog';
import NoticeModifyDialog from './NoticeModifyDialog';

const Notice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let previousState = location.state;
  const [totalCount, setTotalCount] = useState(0);
  const [noticeList, setNoticeList] = useState([]);
  const [isRegisterDialogOpen, setIsRegisterDialogOpen] = useState(false);
  const [isModifyDialogOpen, setIsModifyDialogOpen] = useState(false);
  const [notice, setNotice] = useState(null);
  const [isMain, setIsMain] = useState(false);

  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getNoticeListInit(previousState);
  }, [intPage]);

  const getNoticeListInit = async (previousStateProps) => {
    switch (previousStateProps) {
      case true: {
        const response = await getNoticeMain({
          page: 1,
          size: 10,
        });
        if (response) {
          setIsMain(true);
          setNoticeList(response.content);
          setTotalCount(response.totalElements);
        }
        break;
      }
      case null: {
        const response = await getNoticeList({
          page: intPage,
          size: 10,
        });
        if (response) {
          setIsMain(false);
          setNoticeList(response.content);
          setTotalCount(response.totalElements);
        }
        break;
      }
      default: {
        const response = await getNoticeList({
          page: intPage,
          size: 10,
        });
        if (response) {
          setIsMain(false);
          setNoticeList(response.content);
          setTotalCount(response.totalElements);
        }
      }
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/notice/${page}`);
  };

  const searchClick = async () => {
    if (isMain) {
      if (intPage === 1) {
        getNoticeListInit(null);
      } else {
        navigate(`/notice/1`, { state: null });
      }
    } else {
      if (intPage === 1) {
        getNoticeListInit(true);
      } else {
        navigate(`/notice/${1}`, { state: true });
      }
    }
  };

  const clickModifyDialog = (notice) => {
    setNotice(notice);
    setIsModifyDialogOpen(true);
  };

  const handleNoticeDeleteButtonClick = (id) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      deleteNoticeFunc(id);
    } else {
      return;
    }
  };

  const deleteNoticeFunc = async (id) => {
    const response = await deleteNotice({ id: id });
    if (response === 'success') {
      alert('삭제완료');
      window.location.reload(true);
    } else {
      alert('삭제실패');
    }
  };

  const dateChanger = (date) => {
    const dateObject = new Date(date);

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getSeconds().toString().padStart(2, '0');
    return `${year}년 ${month}월 ${day}일 ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className={styles.wrapper}>
      {isRegisterDialogOpen ? (
        <NoticeRegisterDialog
          setIsRegisterDialogOpen={setIsRegisterDialogOpen}
        />
      ) : null}
      {isModifyDialogOpen ? (
        <NoticeModifyDialog
          setIsModifyDialogOpen={setIsModifyDialogOpen}
          notice={notice}
        />
      ) : null}
      <div className={styles.pageTitle}>공지 관리</div>
      <div className={styles.aboveContainer}>
        <div
          className={isMain ? styles.searchButtonSelect : styles.searchButton}
          onClick={() => {
            searchClick();
          }}>
          홈화면 공지
        </div>
        <div
          className={styles.addButton}
          onClick={() => {
            setIsRegisterDialogOpen(true);
          }}>
          추가하기
        </div>
      </div>
      <div className={styles.listContainer}>
        <div className={styles.totalCount}>전체 : {totalCount} 건</div>
        <div className={styles.listTitle}>
          <p>제목</p>
          <p>등록일</p>
          <p>홈화면 등록여부</p>
        </div>
        {noticeList.length === 0 ? (
          <div className={styles.emptyText}>작성한 공지가 없습니다</div>
        ) : (
          noticeList.map((notice) => (
            <div key={notice.id} className={styles.banner}>
              <p title={notice.title}>{notice.title}</p>
              <p>{dateChanger(notice.date)}</p>
              <p>{notice.main ? 'O' : 'X'}</p>
              <p
                className={styles.modifyButton}
                onClick={() => {
                  clickModifyDialog(notice);
                }}>
                수정
              </p>
              <p
                className={styles.deleteButton}
                onClick={() => handleNoticeDeleteButtonClick(notice.id)}>
                삭제
              </p>
            </div>
          ))
        )}
        <div className={styles.pagingContainer}>
          <Paging
            page={intPage}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Notice;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import Paging from '../util/Paging';
// import styles from '../with-together/WithTogether.module.css';
import styles from './EventSchedule.module.css';
import {
  getEventSchedule,
  getEventScheduleByCategory,
  deleteEventSchedule,
  getEventScheduleSearch,
} from '../../network/api/EventScheduleAPI';

import ImageDialog from '../util/ImageDialog';
import EventScheduleModifyDialog from './EventScheduleModifyDialog';
import EventScheduleRegisterDialog from './EventScheduleRegisterDialog';

const EventSchedule = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [categoryList, setCategoryList] = useState([
    {
      category: '행사',
      isSelected: false,
    },
    {
      category: '교육',
      isSelected: false,
    },
    {
      category: '축제',
      isSelected: false,
    },
  ]);
  const [eventScheduleList, setEventScheduleList] = useState([]);
  const [eventSchedule, setEventSchedule] = useState(null);
  const [category, setCategory] = useState('');
  const [imageUrlList, setImageUrlList] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [isModifyDialogOpen, setIsModifyDialogOpen] = useState(false);
  const [
    isRegisterEventScheduleDialogOpen,
    setIsRegisterEventScheduleDialogOpen,
  ] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getEventSchedulePlaceInit();
  }, [intPage, categoryList]);

  const getEventSchedulePlaceInit = async () => {
    if (searchValue !== '') {
      const response = await getEventScheduleSearch({
        input: searchValue,
        page: intPage,
        size: 10,
      });
      if (response) {
        if (response === undefined) {
          return;
        }
        try {
          setEventScheduleList(response.content);
          setTotalCount(response.totalElements);
        } catch (error) {
          console.error('데이터 불러오기 실패:', error);
        }
      }
    } else {
      if (allItemsUnselected()) {
        const response = await getEventSchedule({
          page: intPage,
          size: 10,
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setEventScheduleList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      } else {
        let categoryList = checkCategory();
        const response = await getEventScheduleByCategory({
          page: intPage,
          size: 10,
          categoryList: categoryList.join(', '),
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setEventScheduleList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      }
    }
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick();
    }
  };

  const searchClick = async () => {
    intPage = 1;
    getEventSchedulePlaceInit();
  };

  const allItemsUnselected = () => {
    return categoryList.every((item) => !item.isSelected);
  };

  const checkCategory = () => {
    let tempCategoryList = [];
    for (const item of categoryList) {
      if (item.isSelected) {
        tempCategoryList.push(item.category);
      }
    }
    return tempCategoryList;
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const menu = categoryList.map((categoryItem, index) => (
    <div
      key={index}
      className={
        categoryItem.isSelected ? styles.slectedMenuItem : styles.menuItem
      }
      onClick={() => {
        handleToggleCategoryList(categoryItem);
      }}>
      {categoryItem.category}
    </div>
  ));

  const handleToggleCategoryList = (categoryItem) => {
    if (intPage === 1) {
      setCategoryList(
        categoryList.map((item) => {
          if (item.category === categoryItem.category) {
            return { ...item, isSelected: !item.isSelected };
          } else {
            return item;
          }
        })
      );
      intPage = 1;
      setCategory(categoryItem.category);
    } else {
      navigate(`/event-schedule/${1}`, { state: category });
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/event-schedule/${page}`, { state: category });
  };

  const changeDateFormat = (dateString) => {
    if (dateString !== '' && dateString !== null) {
      const dateParts = dateString.split('-');
      const year = dateParts[0];
      const month = dateParts[1];
      const day = dateParts[2].split('T')[0];
      return `${year}년 ${month}월 ${day}일`;
    } else {
      return '-';
    }
  };

  const clickOpenDialog = (imageUrlList) => {
    setImageUrlList(imageUrlList);
    setIsImageDialogOpen(true);
  };

  const clickModifyDialog = (participatePlace) => {
    setEventSchedule(participatePlace);
    setIsModifyDialogOpen(true);
  };

  const handleEventScheduleDeleteButtonClick = (id) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      deleteVisitPlaceFunc(id);
    } else {
      return;
    }
  };

  const deleteVisitPlaceFunc = async (id) => {
    const response = await deleteEventSchedule({ id: id });
    if (response === 'success') {
      alert('삭제완료');
      window.location.reload(true);
    } else {
      alert('삭제실패');
    }
  };

  return (
    <div className={styles.wrapper}>
      {isImageDialogOpen && imageUrlList !== null ? (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrlList={imageUrlList}
        />
      ) : null}
      {isRegisterEventScheduleDialogOpen ? (
        <EventScheduleRegisterDialog
          setIsRegisterParticipatePlaceDialogOpen={
            setIsRegisterEventScheduleDialogOpen
          }
          category={category}
          intPage={intPage}
        />
      ) : null}
      {isModifyDialogOpen ? (
        <EventScheduleModifyDialog
          setIsModifyDialogOpen={setIsModifyDialogOpen}
          eventSchedule={eventSchedule}
          category={category}
          intPage={intPage}
        />
      ) : null}
      <div className={styles.innerWrapper}>
        <div className={styles.pageTitle}>행사 · 교육 관리</div>
        <div className={styles.aboveContainer}>
          <ScrollMenu onWheel={onWheel}>{menu}</ScrollMenu>
          <input
            type='text'
            className={styles.searchInput}
            placeholder='검색어 입력'
            onChange={searchInputHandler}
            onKeyDown={handleKeyDown}
            value={searchValue}
          />
          <div
            className={styles.searchButton}
            onClick={() => {
              searchClick();
            }}>
            검색
          </div>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <div className={styles.listTitle}>
            <p>카테고리</p>
            <p>행사이름</p>
            <p>연락처</p>
            <p>링크</p>
            <p>지역</p>
            <p>장소</p>
            <p>지번주소</p>
            <p>도로명주소</p>
            <p>기간</p>
            <p>금액</p>
            <p>금액공지</p>
            <p>안내문구</p>
            <p>이미지</p>
            <p
              onClick={() => {
                setIsRegisterEventScheduleDialogOpen(true);
              }}>
              추가하기
            </p>
          </div>
          {eventScheduleList.length === 0 ? (
            <div className={styles.emptyText}>참여할 행사가 없습니다</div>
          ) : (
            eventScheduleList.map((participatePlace) => (
              <div key={participatePlace.id} className={styles.banner}>
                <p>{participatePlace.category}</p>
                <p>{participatePlace.name}</p>
                <p>{participatePlace.phone}</p>
                <p>
                  <div
                    onClick={() => {
                      window.open(participatePlace.linkUrl, '_blank');
                    }}>
                    {participatePlace.homepageUrl}
                  </div>
                </p>
                <p>{participatePlace.location}</p>
                <p title={participatePlace.detailLocation}>
                  {participatePlace.detailLocation}
                </p>
                <p>{participatePlace.jibunAddress}</p>
                <p>{participatePlace.roadAddress}</p>
                <p>
                  {changeDateFormat(participatePlace.startTime)} <br /> ~ <br />
                  {changeDateFormat(participatePlace.finishTime)}
                </p>
                <p>
                  {participatePlace.lowestPrice !== null &&
                  participatePlace.lowestPrice !== ''
                    ? `${participatePlace.lowestPrice}원`
                    : '-'}
                  <br /> ~ <br />
                  {participatePlace.maximumPrice !== null &&
                  participatePlace.maximumPrice !== ''
                    ? `${participatePlace.maximumPrice}원`
                    : '-'}
                </p>
                <p title={participatePlace.priceInformation}>
                  {participatePlace.priceInformation}
                </p>
                <p title={participatePlace.notice}>{participatePlace.notice}</p>
                <p>
                  <div
                    onClick={() => {
                      clickOpenDialog(participatePlace.imageUrlList);
                    }}>
                    이미지 보기
                  </div>
                </p>
                <p
                  className={styles.modifyButton}
                  onClick={() => {
                    clickModifyDialog(participatePlace);
                  }}>
                  수정
                </p>
                <p
                  className={styles.deleteButton}
                  onClick={() =>
                    handleEventScheduleDeleteButtonClick(participatePlace.id)
                  }>
                  삭제
                </p>
              </div>
            ))
          )}
          <div className={styles.pagingContainer}>
            <Paging
              page={intPage}
              countPerPage={10}
              pageRangeDisplayed={5}
              count={totalCount}
              setPage={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSchedule;

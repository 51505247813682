import { baseInstance, authInstance } from '../AxiosClient';

export const getReportList = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`report/get`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getReportListNickname = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`report/get/report/nickname`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteReport = async (id) => {
  try {
    const { data } = await authInstance.delete(`report/delete/report`, {
      params: id,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

import React, { useRef } from 'react';
import styles from '../event-schedule/EventScheduleModifyDialog.module.css';
import PropTypes from 'prop-types';

const FileInput = ({ index, onChange, image, deleteImage, isEmpty }) => {
  const fileInputRef = useRef(null);

  const handleChange = (event) => {
    const files = fileInputRef.current.files;
    // 여기서 인덱스와 파일 리스트를 전달합니다.
    onChange(files, index, isEmpty);
    event.target.value = '';
  };

  const handleButtonClick = () => {
    // 파일 입력 상자를 클릭하여 파일 선택을 유도합니다.
    fileInputRef.current.click();
  };

  return (
    <div className={styles.imageBoxWrapper}>
      <input
        id='input-store-image-upload'
        type='file'
        ref={fileInputRef}
        accept='.jpg, .jpeg, .png'
        onChange={handleChange}
        style={{
          position: 'absolute',
          left: '-1000px',
          visibility: 'hidden',
        }}
      />
      <img
        className={styles.afterImageContainer}
        onClick={handleButtonClick}
        src={image}
      />
      {!isEmpty ? (
        <div
          className={styles.deleteButton}
          onClick={() => {
            deleteImage(index);
          }}>
          삭제
        </div>
      ) : null}
    </div>
  );
};

FileInput.propTypes = {
  index: PropTypes.number,
  onChange: PropTypes.func,
  image: PropTypes.string,
  deleteImage: PropTypes.func,
  isEmpty: PropTypes.bool,
};

export default FileInput;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './EventBannerRegisterDialog.module.css';
import PropTypes from 'prop-types';
import { registerEventBannerLink } from '../../network/api/EventBannerAPI';
import altImage from '../../resource/images/alt_image.png';
import CloseButton from '../../resource/images/svg/CloseButton';
import { compressImage } from '../../utils/compressImage';

let imageFileBlob = null;

const EventBannerRegisterDialog = ({ setIsRegisterDialogOpen, intPage }) => {
  const inputRef = useRef(null);
  const [bannerLink, setBannerLink] = useState('');
  const [image, setImage] = useState(null);
  const [bannerPosition, setBannerPosition] = useState('상');

  useEffect(() => {
    return () => {
      imageFileBlob = null;
    };
  }, []);

  const bannerLinkInputHandler = (e) => {
    setBannerLink(e.target.value);
  };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        alert('파일 크기는 20MB 이하여야 합니다.');
      } else {
        const compressedImage = await compressImage(file);
        const fileURL = URL.createObjectURL(compressedImage);
        imageFileBlob = compressedImage;
        setImage(fileURL);
      }
    }
    event.target.value = '';
  };

  const openCamera = () => inputRef.current && inputRef.current.click();

  const modifyButtonClick = async () => {
    if (image !== null && image !== '') {
      const formData = new FormData();
      formData.append('multipartFile', imageFileBlob);
      const uploaderString = JSON.stringify({
        linkUrl: bannerLink,
        position: bannerPosition,
      });
      formData.append(
        'modifyBannerRequest',
        new Blob([uploaderString], { type: 'application/json' })
      );
      const response = await registerEventBannerLink(formData);
      if (response === 'success') {
        window.location.href = `/event-banner/${intPage}`;
      } else {
        alert('등록실패');
      }
    } else {
      alert('이미지를 등록해 주세요');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsRegisterDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.inputTitle}>배너 위치</div>
        <div className={styles.genderRadioGroup}>
          <div
            className={styles.radioBox}
            onClick={() => {
              setBannerPosition('상');
            }}>
            {bannerPosition === '상' ? (
              <div className={styles.selectedRadio}>
                <div className={styles.selectedRadioInner} />
              </div>
            ) : (
              <div className={styles.unselectedRadio} />
            )}
            <div className={styles.radioText}>상단배너</div>
          </div>
          <div
            className={styles.radioBox}
            onClick={() => {
              setBannerPosition('중');
            }}>
            {bannerPosition === '중' ? (
              <div className={styles.selectedRadio}>
                <div className={styles.selectedRadioInner} />
              </div>
            ) : (
              <div className={styles.unselectedRadio} />
            )}
            <div className={styles.radioText}>띠배너</div>
          </div>
          <div
            className={styles.radioBox}
            onClick={() => {
              setBannerPosition('하');
            }}>
            {bannerPosition === '하' ? (
              <div className={styles.selectedRadio}>
                <div className={styles.selectedRadioInner} />
              </div>
            ) : (
              <div className={styles.unselectedRadio} />
            )}
            <div className={styles.radioText}>반려로운 소식</div>
          </div>
        </div>
        <div className={styles.inputTitle}>배너 링크</div>
        <input
          type='text'
          maxLength={2000}
          className={styles.inputId}
          placeholder='배너 링크'
          onChange={bannerLinkInputHandler}
          value={bannerLink}
        />
        <div className={styles.inputTitle}>배너 이미지</div>
        <div className={styles.imageContainer}>
          {image !== null && image !== '' ? (
            <>
              <input
                id='input-store-image-upload'
                type='file'
                ref={inputRef}
                accept='.jpg, .jpeg, .png'
                onChange={handleFileInputChange}
                style={{
                  position: 'absolute',
                  left: '-1000px',
                  visibility: 'hidden',
                }}
              />
              <img
                className={styles.afterImageContainer}
                onClick={openCamera}
                src={image}
              />
            </>
          ) : (
            <>
              <input
                id='input-store-image-upload'
                type='file'
                ref={inputRef}
                accept='.jpg, .jpeg, .png'
                onChange={handleFileInputChange}
                style={{
                  position: 'absolute',
                  left: '-1000px',
                  visibility: 'hidden',
                }}
              />
              <label
                htmlFor='input-store-image-upload'
                className={styles.imageUploadButton}>
                <img className={styles.profileBox} src={altImage} />
              </label>
            </>
          )}
        </div>
        <div
          className={styles.loginButton}
          onClick={() => {
            modifyButtonClick();
          }}>
          등록하기
        </div>
      </div>
    </div>
  );
};

EventBannerRegisterDialog.propTypes = {
  setIsRegisterDialogOpen: PropTypes.func,
  intPage: PropTypes.number,
};

export default EventBannerRegisterDialog;

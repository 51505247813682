import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Paging from '../util/Paging';
import Select from 'react-select';
import styles from './Dog.module.css';
import {
  getDogList,
  getDogListWithSearch,
  permitDogStatus,
  rejectDogStatus,
} from '../../network/api/DogAPI';

const searchOptions = [
  { value: 'nickname', label: '닉네임' },
  { value: 'name', label: '강아지 이름' },
  { value: 'number', label: '등록번호' },
  { value: 'owner', label: '소유자' },
];

const Dog = () => {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [dogList, setDogList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getDogInit();
  }, [intPage]);

  const getDogInit = async () => {
    const response = await getDogList({
      page: intPage,
      size: 10,
    });
    if (response) {
      setDogList(response.content);
      setTotalCount(response.totalElements);
      setIsLoaded(true);
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/dog${page}`);
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick();
    }
  };

  const searchClick = async () => {
    setIsLoaded(false);
    if (selectedOption !== null) {
      if (searchValue !== '') {
        if (selectedOption.value === 'number') {
          if (!/^\d*$/.test(searchValue)) {
            alert('반려동물 등록번호는 숫자만 입력해주세요');
            setSearchValue('');
            return;
          }
        }
        const response = await getDogListWithSearch({
          page: intPage,
          size: 10,
          option: selectedOption.value,
          input: searchValue,
        });
        if (response) {
          setDogList(response.content);
          setTotalCount(response.totalElements);
        }
      } else {
        alert('검색할 내용을 입력해주세요');
      }
    } else {
      alert('검색할 옵션을 선택해주세요');
    }
    setIsLoaded(true);
  };

  const resetOption = () => {
    setSelectedOption(null);
    setSearchValue('');
    intPage = 1;
    getDogInit();
  };

  const dateChanger = (date) => {
    if (date !== null) {
      const dateObject = new Date(date);

      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
      const day = dateObject.getDate().toString().padStart(2, '0');
      const hours = dateObject.getHours().toString().padStart(2, '0');
      const minutes = dateObject.getMinutes().toString().padStart(2, '0');
      const seconds = dateObject.getSeconds().toString().padStart(2, '0');
      return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
    }
  };

  const permitButtonClick = async (status, id) => {
    if (status !== '승인') {
      const response = await permitDogStatus({ id: id });

      if (response === 'success') {
        window.location.reload(true);
      } else {
        alert('승인실패');
      }
    }
  };

  const rejectButtonClick = async (status, id) => {
    if (status !== '반려') {
      const response = await rejectDogStatus({ id: id });

      if (response === 'success') {
        window.location.reload(true);
      } else {
        alert('반려실패');
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.pageTitle}>반려동물 관리</div>
        {isLoaded ? (
          <>
            <div className={styles.searchContainer}>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                options={searchOptions}
                placeholder='선택'
              />
              <input
                type='text'
                className={styles.searchInput}
                placeholder='입력'
                onChange={searchInputHandler}
                onKeyDown={handleKeyDown}
                value={searchValue}
              />
              <div
                className={styles.searchButton}
                onClick={() => {
                  searchClick();
                }}>
                검색
              </div>
              <div
                className={styles.searchButton}
                onClick={() => {
                  resetOption();
                }}>
                초기화
              </div>
            </div>
            <div className={styles.totalCount}>전체 : {totalCount} 건</div>
            <div className={styles.listContainer}>
              <div className={styles.dogListTitleBox}>
                <p>유저 닉네임</p>
                <p>반려동물</p>
                <p>이름</p>
                <p>견종</p>
                <p>생일</p>
                <p>몸무게</p>
                <p>성별</p>
                <p>중성화 여부</p>
                <p>반려동물 등록번호</p>
                <p>등록 소유자</p>
                <p>승인상태</p>
                <p>등록일</p>
                <p>승인일</p>
                <p>반려일</p>
                <p className={styles.emptyTitle}></p>
              </div>
              {dogList.length === 0 ? (
                <div className={styles.emptyText}>이벤트 배너가 없습니다</div>
              ) : (
                dogList.map((dog) => (
                  <div key={dog.id} className={styles.banner}>
                    <div title={dog.nickname}>
                      <p
                        onClick={() => {
                          navigate(`/user/1`, {
                            state: { nickname: dog.nickname },
                          });
                        }}>
                        {dog.nickname}
                      </p>
                    </div>
                    <div>{dog.species === 'dog' ? '강아지' : dog.species}</div>
                    <div title={dog.name}>{dog.name}</div>
                    <div title={dog.breed}>{dog.breed}</div>
                    <div>{dog.birthday}</div>
                    <div>{dog.weight}kg</div>
                    <div>{dog.gender === 'female' ? '여' : '남'}</div>
                    <div>{dog.neutered ? 'O' : 'X'}</div>
                    <div
                      title={
                        dog.animalRegistrationNumber !== ''
                          ? dog.animalRegistrationNumber
                          : ''
                      }>
                      {dog.animalRegistrationNumber !== ''
                        ? dog.animalRegistrationNumber
                        : '미등록'}
                    </div>
                    <div>{dog.ownerName !== '' ? dog.ownerName : '미등록'}</div>
                    <div
                      className={
                        dog.status === '승인'
                          ? styles.blueText
                          : dog.status === '반려'
                          ? styles.redText
                          : null
                      }>
                      {dog.status}
                    </div>
                    <div>{dateChanger(dog.registerDate)}</div>
                    <div>{dateChanger(dog.permitDate)}</div>
                    <div>{dateChanger(dog.rejectDate)}</div>
                    <div
                      className={styles.permitButton}
                      onClick={() => {
                        permitButtonClick(dog.status, dog.id);
                      }}>
                      승인
                    </div>
                    <div
                      className={styles.rejectButton}
                      onClick={() => {
                        rejectButtonClick(dog.status, dog.id);
                      }}>
                      반려
                    </div>
                  </div>
                ))
              )}
              <div className={styles.pagingContainer}>
                <Paging
                  page={intPage}
                  countPerPage={10}
                  pageRangeDisplayed={5}
                  count={totalCount}
                  setPage={handlePageChange}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Dog;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './EventBanner.module.css';
import { getEventBanner } from '../../network/api/EventBannerAPI';
import Paging from '../util/Paging';
import ImagePopup from '../util/ImageDialog';
import EventBannerModifyDialog from './EventBannerModifyDialog';
import { deleteEventBanner } from '../../network/api/EventBannerAPI';
import EventBannerRegisterDialog from './EventBannerRegisterDialog';

const EventBanner = () => {
  const navigate = useNavigate();
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [isModifyDialogOpen, setIsModifyDialogOpen] = useState(false);
  const [isRegisterDialogOpen, setIsRegisterDialogOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [eventBannerList, setEventBannerList] = useState([]);
  const [linkUrl, setLinkUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [eventBannerId, setEventBannerId] = useState(null);

  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getEventBannerInit();
  }, [intPage]);

  const getEventBannerInit = async () => {
    const response = await getEventBanner({
      page: intPage,
      size: 12,
    });
    if (response) {
      setEventBannerList(response.content);
      setTotalCount(response.totalElements);
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/event-banner/${page}`);
  };

  const clickOpenDialog = (imageUrl) => {
    setImageUrl(imageUrl);
    setIsImageDialogOpen(true);
  };

  const clickModifyDialog = (bannerId, linkUrl, imageUrl) => {
    setEventBannerId(bannerId);
    setLinkUrl(linkUrl);
    setImageUrl(imageUrl);
    setIsModifyDialogOpen(true);
  };

  const handleEventBannerDeleteButtonClick = (bannerId) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      clickDeleteDialog(bannerId);
    } else {
      return;
    }
  };

  const clickDeleteDialog = async (bannerId) => {
    const response = await deleteEventBanner({ id: bannerId });
    if (response === 'success') {
      alert('삭제완료');
      window.location.reload(true);
    } else {
      alert('삭제실패');
    }
  };

  return (
    <div className={styles.wrapper}>
      {isRegisterDialogOpen ? (
        <EventBannerRegisterDialog
          setIsRegisterDialogOpen={setIsRegisterDialogOpen}
          intPage={intPage}
        />
      ) : null}
      {isModifyDialogOpen && imageUrl !== null && eventBannerId !== null ? (
        <EventBannerModifyDialog
          eventBannerId={eventBannerId}
          setIsModifyDialogOpen={setIsModifyDialogOpen}
          linkUrl={linkUrl}
          imageUrl={imageUrl}
          intPage={intPage}
        />
      ) : null}
      {isImageDialogOpen && imageUrl !== null ? (
        <ImagePopup
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrl={imageUrl}
        />
      ) : null}
      <div className={styles.innerWrapper}>
        <div className={styles.pageTitle}>이벤트 배너 관리</div>
        <div className={styles.listContainer}>
          <div className={styles.aboveContainer}>
            <div
              className={styles.addButton}
              onClick={() => {
                setIsRegisterDialogOpen(true);
              }}>
              추가하기
            </div>
          </div>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <div className={styles.listTitle}>
            <p>배너 위치</p>
            <p>배너 링크</p>
            <p>이미지</p>
          </div>
          {eventBannerList.length === 0 ? (
            <div className={styles.emptyText}>이벤트 배너가 없습니다</div>
          ) : (
            eventBannerList.map((evnetBanner) => (
              <div key={evnetBanner.id} className={styles.banner}>
                <p>
                  {evnetBanner.position === '상'
                    ? '상단배너'
                    : evnetBanner.position === '중'
                    ? '띠배너'
                    : '반려로운 소식'}
                </p>
                <p>
                  <div
                    onClick={() => {
                      window.open(evnetBanner.linkUrl, '_blank');
                    }}>
                    {evnetBanner.linkUrl}
                  </div>
                </p>
                <p
                  onClick={() => {
                    clickOpenDialog(evnetBanner.imageUrl);
                  }}>
                  이미지 보기
                </p>
                <p
                  className={styles.modifyButton}
                  onClick={() => {
                    clickModifyDialog(
                      evnetBanner.id,
                      evnetBanner.linkUrl,
                      evnetBanner.imageUrl
                    );
                  }}>
                  수정
                </p>
                <p
                  className={styles.deleteButton}
                  onClick={() =>
                    handleEventBannerDeleteButtonClick(evnetBanner.id)
                  }>
                  삭제
                </p>
              </div>
            ))
          )}
          <div className={styles.pagingContainer}>
            <Paging
              page={intPage}
              countPerPage={12}
              pageRangeDisplayed={5}
              count={totalCount}
              setPage={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventBanner;

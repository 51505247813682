export const AUTH_DIALOG_TOGGLE = 'AUTH_DIALOG_TOGGLE';
export const ALERT_DIALOG_TOGGLE = 'ALERT_DIALOG_TOGGLE';
export const ALERT_DIALOG_MESSAGE = 'ALERT_DIALOG_MESSAGE';
export const SET_ROUTE = 'SET_ROUTE';

export const authDialogToggle = () => {
  return {
    type: AUTH_DIALOG_TOGGLE,
  };
};

export const alertDialogToggle = () => {
  return {
    type: ALERT_DIALOG_TOGGLE,
  };
};

export const setAlertDialogMessage = (message) => {
  return {
    type: ALERT_DIALOG_MESSAGE,
    payload: message,
  };
};

export const setGoRoute = (route) => {
  return {
    type: SET_ROUTE,
    payload: route,
  };
};

import { baseInstance, authInstance } from '../AxiosClient';

export const getAllAlarmList = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`alarm/get/all`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getAllAlarmListByNickname = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`alarm/get/all/nickname`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const sendPushAll = async (pushInfo) => {
  try {
    const { data } = await authInstance.post(`alarm/push`, pushInfo);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const sendPushSpecific = async (pushInfo) => {
  try {
    const { data } = await authInstance.post(`alarm/push/specific`, pushInfo);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

import { baseInstance, authInstance } from '../AxiosClient';

export const getParticipateCategory = async () => {
  try {
    const { data } = await authInstance.get(`participateEvent/get/category`);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getParticipatePlaceDataById = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`participateEvent/get/id`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const registerParticipatePlaceCategory = async (participateCategory) => {
  try {
    const { data } = await authInstance.post(
      `participateEvent/register/category`,
      participateCategory
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyParticipatePlaceCategory = async (participateCategory) => {
  try {
    const { data } = await authInstance.post(
      `participateEvent/modify/category`,
      participateCategory
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteParticipatePlaceCategory = async (id) => {
  try {
    const { data } = await authInstance.delete(
      `participateEvent/delete/category`,
      {
        params: id,
      }
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const registerParticipateEvent = async (participateEvent) => {
  try {
    const { data } = await authInstance.post(
      `participateEvent/register`,
      participateEvent
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteParticipateEvent = async (participateInfo) => {
  try {
    const { data } = await authInstance.delete(`participateEvent/delete`, {
      params: participateInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

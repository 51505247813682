import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Paging from '../util/Paging';
import styles from './User.module.css';
import { getUserList, getUserListWithSearch } from '../../network/api/UserAPI';
import ImageDialog from '../util/ImageDialog';
import UserDogDialog from './UserDogDialog';
import Select from 'react-select';

const searchOptions = [
  { value: 'email', label: '이메일' },
  { value: 'name', label: '이름' },
  { value: 'nickname', label: '닉네임' },
  { value: 'phonenumber', label: '휴대폰' },
];

const User = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [totalCount, setTotalCount] = useState(0);
  const [userList, setUserList] = useState([]);
  const [image, setImage] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [isDogDialogOpen, setIsDogDialogOpen] = useState(false);
  const [dogList, setDogList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    if (location.state !== null) {
      setSelectedOption({ value: 'nickname', label: '닉네임' });
      setSearchValue(location.state.nickname);
      searchClick(
        true,
        { value: 'nickname', label: '닉네임' },
        location.state.nickname
      );
    } else {
      getNoticeListInit();
    }
  }, [intPage]);

  const getNoticeListInit = async () => {
    const response = await getUserList({
      page: intPage,
      size: 10,
    });
    if (response) {
      setUserList(response.content);
      setTotalCount(response.totalElements);
    }
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick(false);
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/notice/${page}`);
  };

  const clickDogDialog = (dogList) => {
    setDogList(dogList);
    setIsDogDialogOpen(true);
  };

  const clickOpenDialog = (image) => {
    if (image !== null && image !== undefined && image !== '') {
      setImage(image);
      setIsImageDialogOpen(true);
    } else {
      alert('등록된 이미지가 없습니다');
    }
  };

  const dateChanger = (date) => {
    if (date !== undefined && date !== null) {
      const dateObject = new Date(date);

      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
      const day = dateObject.getDate().toString().padStart(2, '0');
      const hours = dateObject.getHours().toString().padStart(2, '0');
      const minutes = dateObject.getMinutes().toString().padStart(2, '0');
      const seconds = dateObject.getSeconds().toString().padStart(2, '0');
      return `${year}년 ${month}월 ${day}일 ${hours}:${minutes}:${seconds}`;
    } else {
      return '-';
    }
  };

  const searchClick = async (isSend, option, value) => {
    if (isSend) {
      const response = await getUserListWithSearch({
        page: intPage,
        size: 10,
        option: option.value,
        input: value,
      });
      if (response) {
        setUserList(response.content);
        setTotalCount(response.totalElements);
        window.history.replaceState({}, document.title);
      }
    } else {
      if (selectedOption !== null) {
        if (searchValue !== '') {
          if (selectedOption.value === 'phonenumber') {
            if (!/^\d*$/.test(searchValue)) {
              alert('휴대폰번호는 숫자만 입력해주세요');
              setSearchValue('');
              return;
            }
          }
          const response = await getUserListWithSearch({
            page: intPage,
            size: 10,
            option: selectedOption.value,
            input: searchValue,
          });
          if (response) {
            setUserList(response.content);
            setTotalCount(response.totalElements);
          }
        } else {
          alert('검색할 내용을 입력해주세요');
        }
      } else {
        alert('검색할 옵션을 선택해주세요');
      }
    }
  };

  const resetOption = () => {
    setSelectedOption(null);
    setSearchValue('');
    intPage = 1;
    getNoticeListInit();
  };

  return (
    <div className={styles.wrapper}>
      {isDogDialogOpen ? (
        <UserDogDialog
          dogList={dogList}
          setIsDogDialogOpen={setIsDogDialogOpen}
        />
      ) : null}
      {isImageDialogOpen && image !== null ? (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrl={image}
        />
      ) : null}
      <div className={styles.innerWrapper}>
        <div className={styles.pageTitle}>유저관리</div>
        <div className={styles.searchContainer}>
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            options={searchOptions}
            placeholder='선택'
          />
          <input
            type='text'
            className={styles.searchInput}
            placeholder='입력'
            onChange={searchInputHandler}
            onKeyDown={handleKeyDown}
            value={searchValue}
          />
          <div
            className={styles.searchButton}
            onClick={() => {
              searchClick(false);
            }}>
            검색
          </div>
          <div
            className={styles.searchButton}
            onClick={() => {
              resetOption();
            }}>
            초기화
          </div>
        </div>
        <div className={styles.totalCount}>전체 : {totalCount} 건</div>
        <div className={styles.listContainer}>
          <div className={styles.listTitle}>
            <p>닉네임</p>
            <p>이메일(아이디)</p>
            <p>이름</p>
            <p>휴대폰</p>
            <p>프로필 이미지</p>
            <p>가입일</p>
            <p>최근 로그인 일자</p>
            <p>반려동물 등록 여부</p>
          </div>
          {userList.length === 0 ? (
            <div className={styles.emptyText}>작성한 공지가 없습니다</div>
          ) : (
            userList.map((user) => (
              <div key={user.id} className={styles.banner}>
                <p title={user.nickname}>{user.nickname}</p>
                <p>{user.username}</p>
                <p>{user.name}</p>
                <p>{user.phone}</p>
                <p>
                  <div
                    onClick={() => {
                      clickOpenDialog(user.profileUrl);
                    }}>
                    이미지 보기
                  </div>
                </p>
                <p>{dateChanger(user.joinDate)}</p>
                <p>{dateChanger(user.loginDate)}</p>
                <p>{user.dogList.length === 0 ? 'X' : 'O'}</p>
                {user.dogList.length === 0 ? (
                  <p className={styles.emptyButton} />
                ) : (
                  <p
                    className={styles.deleteButton}
                    onClick={() => clickDogDialog(user.dogList)}>
                    반려동물 확인
                  </p>
                )}
              </div>
            ))
          )}
        </div>
        <div className={styles.pagingContainer}>
          <Paging
            page={intPage}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default User;

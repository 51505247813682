import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './EventBannerModifyDialog.module.css';
import PropTypes from 'prop-types';
import {
  modifyEventBanner,
  modifyEventBannerImage,
  modifyEventBannerLink,
} from '../../network/api/EventBannerAPI';
import CloseButton from '../../resource/images/svg/CloseButton';
import { compressImage } from '../../utils/compressImage';

let imageFileBlob = null;

const EventBannerModifyDialog = ({
  eventBannerId,
  setIsModifyDialogOpen,
  linkUrl,
  imageUrl,
  intPage,
}) => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [bannerLink, setBannerLink] = useState(linkUrl);
  const [image, setImage] = useState(imageUrl);

  useEffect(() => {
    return () => {
      imageFileBlob = null;
    };
  }, []);

  const bannerLinkInputHandler = (e) => {
    setBannerLink(e.target.value);
  };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        alert('파일 크기는 20MB 이하여야 합니다.');
      } else {
        const compressedImage = await compressImage(file);
        const fileURL = URL.createObjectURL(compressedImage);
        imageFileBlob = compressedImage;
        setImage(fileURL);
      }
    }
    event.target.value = '';
  };

  const openCamera = () => inputRef.current && inputRef.current.click();

  const modifyButtonClick = async () => {
    if (linkUrl === bannerLink && imageUrl === image) {
      alert('변경사항이 없습니다');
      return;
    } else if (linkUrl === bannerLink && imageUrl !== image) {
      const formData = new FormData();
      formData.append('multipartFile', imageFileBlob);
      const uploaderString = JSON.stringify({ id: eventBannerId });
      formData.append(
        'modifyBannerRequest',
        new Blob([uploaderString], { type: 'application/json' })
      );

      const response = await modifyEventBannerImage(formData);
      if (response.result === 'success') {
        alert('변경완료');
        window.location.href = `/event-banner/${intPage}`;
      } else {
        alert('변경실패');
      }
    } else if (imageUrl === image && linkUrl !== bannerLink) {
      const response = await modifyEventBannerLink({
        id: eventBannerId,
        linkUrl: bannerLink,
      });
      if (response.result === 'success') {
        alert('변경완료');
        window.location.href = `/event-banner/${intPage}`;
      } else {
        alert('변경실패');
      }
    } else {
      const formData = new FormData();
      formData.append('multipartFile', imageFileBlob);
      const uploaderString = JSON.stringify({
        id: eventBannerId,
        linkUrl: bannerLink,
      });
      formData.append(
        'modifyBannerRequest',
        new Blob([uploaderString], { type: 'application/json' })
      );
      const response = await modifyEventBanner(formData);
      if (response.result === 'success') {
        alert('변경완료');
        window.location.href = `/event-banner/${intPage}`;
      } else {
        alert('변경실패');
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsModifyDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.inputTitle}>배너 링크</div>
        <input
          type='text'
          maxLength={2000}
          className={styles.inputId}
          placeholder='배너 링크'
          onChange={bannerLinkInputHandler}
          value={bannerLink}
        />
        <div className={styles.inputTitle}>배너 이미지</div>
        <div className={styles.imageContainer}>
          {image !== null && image !== '' ? (
            <>
              <input
                id='input-store-image-upload'
                type='file'
                ref={inputRef}
                accept='.jpg, .jpeg, .png'
                onChange={handleFileInputChange}
                style={{
                  position: 'absolute',
                  left: '-1000px',
                  visibility: 'hidden',
                }}
              />
              <img
                className={styles.afterImageContainer}
                onClick={openCamera}
                src={image}
              />
            </>
          ) : (
            <>
              <input
                id='input-store-image-upload'
                type='file'
                ref={inputRef}
                accept='.jpg, .jpeg, .png'
                onChange={handleFileInputChange}
                style={{
                  position: 'absolute',
                  left: '-1000px',
                  visibility: 'hidden',
                }}
              />
              <label
                htmlFor='input-store-image-upload'
                className={styles.imageUploadButton}>
                <img className={styles.profileBox} src={imageUrl} />
              </label>
            </>
          )}
        </div>
        <div
          className={styles.loginButton}
          onClick={() => {
            modifyButtonClick();
          }}>
          변경하기
        </div>
      </div>
    </div>
  );
};

EventBannerModifyDialog.propTypes = {
  eventBannerId: PropTypes.number,
  setIsModifyDialogOpen: PropTypes.func,
  linkUrl: PropTypes.string,
  imageUrl: PropTypes.string,
  intPage: PropTypes.string,
};

export default EventBannerModifyDialog;

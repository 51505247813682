import React, { Dispatch, SetStateAction, useEffect } from 'react';
import styles from './MiniMap.module.css';

const markerIcon = `<svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M19 9.51821C19 15.2587 12.3146 21.7874 10.1604 23.7422C9.78152 24.0859 9.21848 24.0859 8.83964 23.7422C6.68538 21.7874 0 15.2587 0 9.51821C0 4.26145 4.2533 0 9.5 0C14.7467 0 19 4.26145 19 9.51821ZM12.9306 9.51821C12.9306 11.4165 11.3946 12.9553 9.5 12.9553C7.60538 12.9553 6.06944 11.4165 6.06944 9.51821C6.06944 7.61994 7.60538 6.08108 9.5 6.08108C11.3946 6.08108 12.9306 7.61994 12.9306 9.51821Z" fill="#F95050"/>
</svg>`;

let map: naver.maps.Map | any;
let marker: naver.maps.Marker;
let currentPosition: naver.maps.LatLng;

interface coord {
  latitude: number;
  longitude: number;
}

interface props {
  roadAddress: String;
  setPlacePoint: Dispatch<SetStateAction<coord | any>>;
  setIsNaverPopUpOpen: Dispatch<SetStateAction<boolean>>;
  setRoadAddress: Dispatch<SetStateAction<string | any>>;
  setJibunAddress: Dispatch<SetStateAction<string | any>>;
}

const MiniMap = ({
  roadAddress,
  setPlacePoint,
  setIsNaverPopUpOpen,
  setRoadAddress,
  setJibunAddress,
}: props) => {
  useEffect(() => {
    const initMap = async () => {
      const position: any = await getPos();
      currentPosition = new naver.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      );
      map = new naver.maps.Map('map', {
        center: new naver.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        ),
        zoom: 14,
        scaleControl: true,
        logoControl: true,
        zoomControl: false,
        mapDataControl: true,
        logoControlOptions: {
          position: naver.maps.Position.BOTTOM_RIGHT,
        },
        scaleControlOptions: {
          position: naver.maps.Position.BOTTOM_RIGHT,
        },
        mapDataControlOptions: {
          position: naver.maps.Position.BOTTOM_LEFT,
        },
      });
      marker = new naver.maps.Marker({
        icon: {
          content: markerIcon,
          origin: new naver.maps.Point(0, 0),
          size: new naver.maps.Size(12, 15),
        },
        position: map.getCenter(),
        map: map,
      });

      naver.maps.Event.addListener(map, 'click', function (e) {
        setPlacePoint({ latitude: e.coord.y, longitude: e.coord.x });
        marker.setPosition(e.coord);
      });

      if (roadAddress !== '') {
        searchAddressToCoordinate(roadAddress);
      } else {
        alert('우편번호 찾기를 진행해 주세요!');
      }
    };

    initMap();
    return () => {
      if (map) {
        map.destroy();
        map = undefined;
      }
    };
  }, []);

  const searchAddressToCoordinate = async (address: any) => {
    naver.maps.Service.geocode(
      {
        query: address,
      },
      function (status, response) {
        if (status === naver.maps.Service.Status.ERROR) {
          if (!address) {
            return alert('Geocode Error, Please check address');
          }
          return alert('Geocode Error, address:' + address);
        }

        if (response.v2.meta.totalCount === 0) {
          return alert('No result.');
        }
        let item: any = response.v2.addresses[0];
        let point: any = new naver.maps.Point(item.x, item.y);

        setPlacePoint({
          latitude: point.y,
          longitude: point.x,
        });
        map.setCenter(point);
        marker.setPosition(point);
      }
    );
  };

  const getPos = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    }).catch((error) => {
      alert('현재위치를 찾을 수 없습니다');
    });
  };

  const closeButtonClick = () => {
    setPlacePoint({ latitude: null, longitude: null });
    setRoadAddress(null);
    setJibunAddress(null);
    setIsNaverPopUpOpen(false);
  };

  const confirmButtonClick = () => {
    setIsNaverPopUpOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div id='map' className={styles.map} />
        <div
          className={styles.loginButton}
          onClick={() => {
            confirmButtonClick();
          }}>
          등록
        </div>
        <div
          className={styles.closeButton}
          onClick={() => {
            closeButtonClick();
          }}>
          닫기
        </div>
      </div>
    </div>
  );
};

export default MiniMap;

import { baseInstance, authInstance } from '../AxiosClient';

export const getVisitPlaceCategory = async () => {
  try {
    const { data } = await authInstance.get(`visitPlaceCategory/get`);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getVisitPlaceData = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`visitPlace/get/page`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const registerVisitPlaceCategory = async (visitCategory) => {
  try {
    const { data } = await authInstance.post(
      `visitPlaceCategory/register`,
      visitCategory
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyVisitPlaceCategory = async (visitCategory) => {
  try {
    const { data } = await authInstance.post(
      `visitPlaceCategory/modify`,
      visitCategory
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteVisitPlaceCategory = async (id) => {
  try {
    const { data } = await authInstance.delete(`visitPlaceCategory/delete`, {
      params: id,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const registerVisitPlace = async (visitPlace) => {
  try {
    const { data } = await authInstance.post(`visitPlace/register`, visitPlace);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyVisitPlace = async (visitPlace) => {
  try {
    const { data } = await authInstance.post(`visitPlace/modify`, visitPlace);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteVisitPlace = async (id) => {
  try {
    const { data } = await authInstance.delete(`visitPlace/delete`, {
      params: id,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyVisitPlaceWithImage = async (visitPlaceForm) => {
  try {
    const { data } = await authInstance.post(
      `visitPlace/modify/image`,
      visitPlaceForm
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

import { baseInstance, authInstance } from '../AxiosClient';

export const getMachine = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`machine/get`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const registerMachine = async (machieInfo) => {
  try {
    const { data } = await authInstance.post(`machine/register`, machieInfo);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyMachine = async (machieInfo) => {
  try {
    const { data } = await authInstance.post(`machine/modify`, machieInfo);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getMachineLog = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`machine/get/log/admin`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getMachineLogDetail = async (info) => {
  try {
    const { data } = await authInstance.get(`machine/get/log/detail/admin`, {
      params: info,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

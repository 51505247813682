import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Paging from '../util/Paging';
import styles from './Alarm.module.css';
import {
  getAllAlarmList,
  getAllAlarmListByNickname,
} from '../../network/api/AlarmAPI';
import ImageDialog from '../util/ImageDialog';
import AlarmSendAllDialog from './AlarmSendAllDialog';

const Alarm = () => {
  const location = useLocation();
  let previousState = location.state;
  const navigate = useNavigate();
  const [alarmList, setAlarmList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [isAll, setIsAll] = useState(false);
  const [isSendAllDialogOpen, setIsSendAllDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [prev, setPrev] = useState(previousState);
  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getReportListInit(previousState);
  }, [intPage]);

  const getReportListInit = async (previousStateProps) => {
    switch (previousStateProps) {
      case 'none': {
        const response = await getAllAlarmList({
          page: intPage,
          size: 10,
        });
        if (response) {
          setPrev('none');
          setIsAll(false);
          setAlarmList(response.content);
          setTotalCount(response.totalElements);
        }
        break;
      }
      case null: {
        const response = await getAllAlarmList({
          page: intPage,
          size: 10,
        });
        if (response) {
          setPrev('none');
          setAlarmList(response.content);
          setTotalCount(response.totalElements);
        }
        break;
      }
      case 'all': {
        const response = await getAllAlarmListByNickname({
          page: intPage,
          size: 10,
          nickname: 'all',
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setPrev('all');
            setIsAll(true);
            setAlarmList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
        break;
      }
      default: {
        const response = await getAllAlarmListByNickname({
          page: intPage,
          size: 10,
          nickname: previousStateProps,
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setPrev(previousStateProps);
            setAlarmList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      }
    }
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick();
    }
  };

  const searchClick = async () => {
    if (searchValue !== '') {
      if (intPage === 1) {
        getReportListInit(searchValue);
      } else {
        navigate(`/alarm/1`, { state: searchValue });
      }
    } else {
      getReportListInit('none');
    }
  };

  const allClick = async () => {
    if (isAll) {
      if (intPage === 1) {
        getReportListInit('none');
      } else {
        navigate(`/alarm/1`, { state: 'none' });
      }
    } else {
      if (intPage === 1) {
        getReportListInit('all');
      } else {
        navigate(`/alarm/1`, { state: 'all' });
      }
    }
  };

  const clickOpenDialog = (imageUrl) => {
    setImageUrl(imageUrl);
    setIsImageDialogOpen(true);
  };

  const handlePageChange = async (page) => {
    navigate(`/alarm/${page}`, { state: prev });
  };

  const dateChanger = (date) => {
    if (date !== null) {
      const dateObject = new Date(date);

      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
      const day = dateObject.getDate().toString().padStart(2, '0');
      const hours = dateObject.getHours().toString().padStart(2, '0');
      const minutes = dateObject.getMinutes().toString().padStart(2, '0');
      const seconds = dateObject.getSeconds().toString().padStart(2, '0');
      return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
    }
  };

  return (
    <div className={styles.wrapper}>
      {isImageDialogOpen && imageUrl !== null ? (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrl={imageUrl}
        />
      ) : null}
      {isSendAllDialogOpen ? (
        <AlarmSendAllDialog setIsSendAllDialogOpen={setIsSendAllDialogOpen} />
      ) : null}
      <div className={styles.innerWrapper}>
        <div className={styles.pageTitle}>푸시 알림 관리</div>
        <div className={styles.aboveContainer}>
          <div>
            <input
              type='text'
              className={styles.searchInput}
              placeholder='수신자 닉네임'
              onChange={searchInputHandler}
              onKeyDown={handleKeyDown}
              value={searchValue}
            />
          </div>
          <div
            className={styles.searchButton}
            onClick={() => {
              searchClick();
            }}>
            검색
          </div>
          <div
            className={isAll ? styles.searchButtonSelect : styles.searchButton}
            onClick={() => {
              allClick();
            }}>
            전체 수신 메시지 보기
          </div>
          <div
            className={styles.searchButton}
            onClick={() => {
              setIsSendAllDialogOpen(true);
            }}>
            전체 알림전송
          </div>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <div className={styles.listTitle}>
            <p>수신자</p>
            <p>알람 제목</p>
            <p>알람 내용</p>
            <p>이미지보기</p>
            <p>전송날짜</p>
            <p>읽음여부</p>
          </div>
          {alarmList.length === 0 ? (
            <div className={styles.emptyText}>알람내역이 없습니다</div>
          ) : (
            alarmList.map((alarm) => (
              <div key={alarm.id} className={styles.banner}>
                <p title={alarm.nickname}>
                  {alarm.nickname === 'all' ? '전체' : alarm.nickname}
                </p>
                <p title={alarm.title}>{alarm.title}</p>
                <p
                  title={alarm.content}
                  dangerouslySetInnerHTML={{
                    __html: alarm.content,
                  }}></p>
                <p
                  onClick={() => {
                    clickOpenDialog(alarm.imageUrl);
                  }}>
                  이미지 보기
                </p>
                <p>{dateChanger(alarm.date)}</p>
                <p>{alarm.readStatus ? 'O' : 'X'}</p>
              </div>
            ))
          )}
          <div className={styles.pagingContainer}>
            <Paging
              page={intPage}
              countPerPage={10}
              pageRangeDisplayed={5}
              count={totalCount}
              setPage={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alarm;

import { baseInstance, authInstance } from '../AxiosClient';
import {
  alertDialogToggle,
  setAlertDialogMessage,
} from '../../store/actions/utilActions';
import { logout } from '../../store/actions/authActions';
import store from '../../store/index';

export const signIn = async (userInfo) => {
  try {
    const { data } = await baseInstance.post(`admin/auth/login`, userInfo);
    switch (data.result) {
      case '일치하는 아이디가 없습니다.':
        store.dispatch(setAlertDialogMessage('일치하는 아이디가 없습니다'));
        store.dispatch(alertDialogToggle());
        return false;
      case '아이디 없음':
        store.dispatch(setAlertDialogMessage('아이디 없음'));
        store.dispatch(alertDialogToggle());
        return false;
      case '탈퇴':
        store.dispatch(setAlertDialogMessage('탈퇴한 회원입니다'));
        store.dispatch(alertDialogToggle());
        return false;
      case '이용정지':
        store.dispatch(setAlertDialogMessage('이용정지된 회원입니다'));
        store.dispatch(alertDialogToggle());
        return false;
      case '10분 경과 안됨':
        store.dispatch(
          setAlertDialogMessage(
            '로그인 시도가 너무 많습니다. 10분 후 다시 시도해 주세요.'
          )
        );
        store.dispatch(alertDialogToggle());
        return false;
      case '비밀번호 틀림':
        store.dispatch(
          setAlertDialogMessage(
            '비밀번호가 일치하지 않습니다. 다시 입력해 주세요'
          )
        );
        store.dispatch(alertDialogToggle());
        return false;
      default:
        if (data.result === 'success') {
          localStorage.setItem('accessToken', data.accessToken);
          return data;
        } else {
          return false;
        }
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const signOut = async () => {
  try {
    const { data } = await baseInstance.get(`admin/auth/logout`);
    if (data) {
      store.dispatch(logout());
      sessionStorage.clear();
      localStorage.clear();
    }
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const signUp = async (userInfo) => {
  try {
    const { data } = await baseInstance.post(`admin/auth/signUp`, userInfo);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getAccessToken = async () => {
  try {
    const { data } = await authInstance.get(`admin/auth/token`);

    switch (data) {
      case '토큰없음':
      case '리프레시 토큰 오류':
      case '로그아웃':
        return false;
      default:
        if (data.success !== null) {
          localStorage.setItem('accessToken', data.success);
          return data.success;
        } else {
          return false;
        }
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getAccessAdmin = async () => {
  try {
    const { data } = await authInstance.get(`admin/auth/get/access`);
    switch (data.result) {
      case '토큰없음':
        return false;
      case '로그아웃':
        return false;
      default:
        if (data.result === 'success') {
          localStorage.setItem('accessToken', data.accessToken);
          return data;
        } else {
          return false;
        }
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

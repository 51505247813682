import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import {
  getRestRoom,
  getRestRoomByName,
  getRestRoomByCategory,
  getRestRoomByNameCategory,
  deletePlace,
} from '../../network/api/PlaceAPI';
import styles from './Poo.module.css';
import ImageDialog from '../util/ImageDialog';
import Paging from '../util/Paging';
import PooRegisterDialog from './PooRegisterDialog';
import PooModifyDialog from './PooModifyDialog';

const Poo = () => {
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([
    {
      category: '화장실',
      isSelected: false,
    },
    {
      category: '자판기',
      isSelected: false,
    },
    {
      category: '휴지통',
      isSelected: false,
    },
  ]);
  const [category, setCategory] = useState('');
  const [pooList, setPooList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [poo, setPoo] = useState(null);
  const [isModifyDialogOpen, setIsModifyDialogOpen] = useState(false);
  const [isRegisterPooDialogOpen, setIsRegisterPooDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getPooPlaceInit();
  }, [intPage, categoryList]);

  const getPooPlaceInit = async () => {
    if (allItemsUnselected()) {
      if (searchValue !== '') {
        const response = await getRestRoomByName({
          page: intPage,
          size: 10,
          name: searchValue,
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setPooList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      } else {
        const response = await getRestRoom({
          page: intPage,
          size: 10,
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setPooList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      }
    } else {
      let categoryList = checkCategory();
      if (searchValue !== '') {
        const response = await getRestRoomByNameCategory({
          page: intPage,
          size: 10,
          name: searchValue,
          categoryList: categoryList.join(', '),
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setPooList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      } else {
        const response = await getRestRoomByCategory({
          page: intPage,
          size: 10,
          categoryList: categoryList.join(', '),
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setPooList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      }
    }
  };

  const allItemsUnselected = () => {
    return categoryList.every((item) => !item.isSelected);
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const menu = categoryList.map((categoryItem, index) => (
    <div
      key={index}
      className={
        categoryItem.isSelected ? styles.slectedMenuItem : styles.menuItem
      }
      onClick={() => {
        handleToggleCategoryList(categoryItem);
      }}>
      {categoryItem.category}
    </div>
  ));

  const handleToggleCategoryList = (categoryItem) => {
    if (intPage === 1) {
      setCategoryList(
        categoryList.map((item) => {
          if (item.category === categoryItem.category) {
            return { ...item, isSelected: !item.isSelected };
          } else {
            return item;
          }
        })
      );
      intPage = 1;
      setCategory(categoryItem.category);
    } else {
      navigate(`/event-schedule/${1}`, { state: category });
    }
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick();
    }
  };

  const searchClick = async () => {
    intPage = 1;
    if (searchValue !== '') {
      const response = await getRestRoomByName({
        page: intPage,
        size: 10,
        name: searchValue,
      });
      if (response) {
        if (response === undefined) {
          return;
        }
        try {
          setPooList(response.content);
          setTotalCount(response.totalElements);
        } catch (error) {
          console.error('데이터 불러오기 실패:', error);
        }
      }
    } else {
      getPooPlaceInit();
    }
  };

  const checkCategory = () => {
    let tempCategoryList = [];
    for (const item of categoryList) {
      if (item.isSelected) {
        tempCategoryList.push(item.category);
      }
    }
    return tempCategoryList;
  };

  const clickOpenDialog = (imageUrl) => {
    setImageUrl(imageUrl);
    setIsImageDialogOpen(true);
  };

  const clickDeleteDialog = (id) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      deletePlaceFunc(id);
    } else {
      return;
    }
  };

  const deletePlaceFunc = async (id) => {
    const response = await deletePlace({ id: id });
    if (response === 'success') {
      alert('삭제완료');
      window.location.reload(true);
    } else {
      alert('삭제실패');
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/event-schedule/${page}`, { state: category });
  };

  const clickModifyDialog = (poo) => {
    setPoo(poo);
    setIsModifyDialogOpen(true);
  };

  const dateChanger = (date) => {
    const dateObject = new Date(date);

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getSeconds().toString().padStart(2, '0');
    return `${year}년 ${month}월 ${day}일 ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className={styles.wrapper}>
      {isImageDialogOpen && imageUrl !== null ? (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrl={imageUrl}
        />
      ) : null}
      {isRegisterPooDialogOpen ? (
        <PooRegisterDialog
          setIsRegisterPooDialogOpen={setIsRegisterPooDialogOpen}
        />
      ) : null}
      {isModifyDialogOpen ? (
        <PooModifyDialog
          poo={poo}
          setIsModifyDialogOpen={setIsModifyDialogOpen}
        />
      ) : null}
      <div className={styles.pageTitle}>댕똥처리 장소 관리</div>
      <div className={styles.aboveContainer}>
        <ScrollMenu onWheel={onWheel}>{menu}</ScrollMenu>
        <input
          type='text'
          className={styles.searchInput}
          placeholder='장소이름 입력'
          onChange={searchInputHandler}
          onKeyDown={handleKeyDown}
          value={searchValue}
        />
        <div
          className={styles.searchButton}
          onClick={() => {
            searchClick();
          }}>
          검색
        </div>
      </div>
      <div className={styles.listContainer}>
        <div className={styles.totalCount}>전체 : {totalCount} 건</div>
        <div className={styles.listTitle}>
          <p>카테고리</p>
          <p>장소이름</p>
          <p>장소 이미지</p>
          <p>등록일</p>
          <p
            onClick={() => {
              setIsRegisterPooDialogOpen(true);
            }}>
            추가하기
          </p>
        </div>
        {pooList.length === 0 ? (
          <div className={styles.emptyText}>장소내역이 없습니다</div>
        ) : (
          pooList.map((poo) => (
            <div key={poo.id} className={styles.banner}>
              <p>{poo.category}</p>
              <p>{poo.name}</p>
              <p
                onClick={() => {
                  clickOpenDialog(poo.imageUrlList[0]);
                }}>
                이미지 보기
              </p>
              <p>{dateChanger(poo.registerDate)}</p>
              <p
                className={styles.modifyButton}
                onClick={() => {
                  clickModifyDialog(poo);
                }}>
                수정
              </p>
              <p
                className={styles.deleteButton}
                onClick={() => clickDeleteDialog(poo.id)}>
                삭제
              </p>
            </div>
          ))
        )}
      </div>
      <div className={styles.pagingContainer}>
        <Paging
          page={intPage}
          countPerPage={10}
          pageRangeDisplayed={5}
          count={totalCount}
          setPage={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Poo;

import React from 'react';
import PropTypes from 'prop-types';

const LeftArrow = ({ styles }) => {
  return (
    <svg
      className={styles}
      width='10'
      height='17'
      viewBox='0 0 10 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.95491 1.7071L2.41421 8.2478L8.95491 14.7886C9.00381 14.8374 9.04651 14.89 9.08311 14.9454C9.33941 15.3336 9.29661 15.8611 8.95491 16.2028C8.56441 16.5933 7.93131 16.5933 7.54071 16.2028L0.29289 8.9549C0.10536 8.7674 0 8.5131 0 8.2478C0 7.9826 0.10536 7.7283 0.29289 7.5407L7.54071 0.29289C7.93131 -0.09763 8.56441 -0.09763 8.95491 0.29289C9.34551 0.68341 9.34551 1.31658 8.95491 1.7071Z'
        fill='#231815'
      />
    </svg>
  );
};

LeftArrow.propTypes = {
  styles: PropTypes.object,
};

export default LeftArrow;

import { combineReducers } from 'redux';
import authReducer from './reducers/authReducer';
import utilReducer from './reducers/utilReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  util: utilReducer,
});

export default rootReducer;

import React from 'react';
import styles from './Navigator.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRoute } from '../../RouterContext';
import { signOut } from '../../network/api/AuthAPI';
import Logo from '../../resource/images/svg/Logo';
import LogoText from '../../resource/images/svg/LogoText';

const Navigator = () => {
  const navigate = useNavigate();
  const { setRoute } = useRoute();
  const location = useLocation();

  const moveRoute = (route) => {
    setRoute(route);
    navigate(route);
  };

  const logoutClick = async () => {
    const response = await signOut();
    if (response) {
      navigate('/authentication/sign-in');
    }
  };

  return (
    <nav className={styles.wrapper}>
      <div className={styles.logoBox}>
        <div onClick={() => moveRoute('/user/1')}>
          <Logo />
          <LogoText />
        </div>
      </div>
      <div className={styles.scrollMenuItems}>
        <div className={styles.menuItem}>
          <div className={styles.mainMenu}>유저</div>
          <div
            className={
              location.pathname.includes('/user/')
                ? styles.selectedMenu
                : styles.subMenu
            }
            onClick={() => moveRoute('/user/1')}>
            유저 관리
          </div>
          <div
            className={
              location.pathname.includes('/dog')
                ? styles.selectedMenu
                : styles.subMenu
            }
            onClick={() => moveRoute('/dog/1')}>
            반려동물 관리
          </div>
          <div
            className={
              location.pathname.includes('/user-machine-log')
                ? styles.selectedMenu
                : styles.subMenu
            }
            onClick={() => moveRoute('/user-machine-log/1')}>
            서비스 이용내역 관리
          </div>
        </div>

        <div className={styles.menuItem}>
          <div className={styles.mainMenu}>홈</div>
          <div
            className={
              location.pathname.includes('/event-banner')
                ? styles.selectedMenu
                : styles.subMenu
            }
            onClick={() => moveRoute('/event-banner/1')}>
            이벤트 배너 관리
          </div>
          <div
            className={
              location.pathname.includes('/visit-place')
                ? styles.selectedMenu
                : styles.subMenu
            }
            onClick={() => moveRoute('/visit-place/1')}>
            가볼 만한 곳 관리
          </div>
          <div
            className={
              location.pathname.includes('/with-together')
                ? styles.selectedMenu
                : styles.subMenu
            }
            onClick={() => moveRoute('/with-together/1')}>
            같이 참여해요 관리
          </div>
        </div>

        <div className={styles.menuItem}>
          <div className={styles.mainMenu}>내주변</div>
          <div
            className={
              location.pathname.includes('/place')
                ? styles.selectedMenu
                : styles.subMenu
            }
            onClick={() => moveRoute('/place/1')}>
            장소 관리
          </div>
        </div>

        <div className={styles.menuItem}>
          <div className={styles.mainMenu}>PUP POOP</div>
          <div
            className={
              location.pathname.includes('/report')
                ? styles.selectedMenu
                : styles.subMenu
            }
            onClick={() => moveRoute('/report/1')}>
            제보 관리
          </div>
          <div
            className={
              location.pathname.includes('/poo')
                ? styles.selectedMenu
                : styles.subMenu
            }
            onClick={() => moveRoute('/poo/1')}>
            댕똥처리 장소 관리
          </div>
          <div
            className={
              location.pathname.includes('/machine/')
                ? styles.selectedMenu
                : styles.subMenu
            }
            onClick={() => moveRoute('/machine/1')}>
            자판기 관리
          </div>
          <div
            className={
              location.pathname.includes('/machine-cb/')
                ? styles.selectedMenu
                : styles.subMenu
            }
            onClick={() => moveRoute('/machine-cb/1')}>
            수거함 관리
          </div>
        </div>

        <div className={styles.menuItem}>
          <div className={styles.mainMenu}>행사 · 교육</div>
          <div
            className={
              location.pathname.includes('/event-schedule')
                ? styles.selectedMenu
                : styles.subMenu
            }
            onClick={() => moveRoute('/event-schedule/1')}>
            행사 · 교육 관리
          </div>
        </div>

        <div className={styles.menuItem}>
          <div className={styles.mainMenu}>공지</div>
          <div
            className={
              location.pathname.includes('/notice')
                ? styles.selectedMenu
                : styles.subMenu
            }
            onClick={() => moveRoute('/notice/1')}>
            공지 관리
          </div>
        </div>

        <div className={styles.menuItem}>
          <div className={styles.mainMenu}>알림</div>
          <div
            className={
              location.pathname.includes('/alarm')
                ? styles.selectedMenu
                : styles.subMenu
            }
            onClick={() => moveRoute('/alarm/1')}>
            푸시 알림 관리
          </div>
        </div>
      </div>
      <div className={styles.logoutBox}>
        <div
          className={styles.logoutButton}
          onClick={() => {
            logoutClick();
          }}>
          로그아웃
        </div>
      </div>
    </nav>
  );
};

export default Navigator;

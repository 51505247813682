import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const RouterContext = createContext();

export const RouterProvider = ({ children }) => {
  const [currentRoute, setCurrentRoute] = useState('');

  const setRoute = (route) => {
    setCurrentRoute(route);
  };

  return (
    <RouterContext.Provider value={{ currentRoute, setRoute }}>
      {children}
    </RouterContext.Provider>
  );
};

export const useRoute = () => {
  return useContext(RouterContext);
};

RouterProvider.propTypes = {
  children: PropTypes.node,
};

import React from 'react';
import './Paging.css';
import Pagination from 'react-js-pagination';

const Paging = ({ page, countPerPage, pageRangeDisplayed, count, setPage }) => {
  return (
    <Pagination
      activePage={page}
      itemsCountPerPage={countPerPage}
      totalItemsCount={count}
      pageRangeDisplayed={pageRangeDisplayed}
      firstPageText={
        <svg
          width='26'
          height='21'
          viewBox='0 0 26 21'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M6.98888 11.4914L11.6761 16.0963C12.005 16.4253 12.4984 16.4253 12.8273 16.0963C13.1563 15.7674 13.1563 15.274 12.8273 14.9451L8.79798 10.8335L12.8273 6.72191C13.1563 6.39299 13.1563 5.8996 12.8273 5.57067C12.6629 5.40621 12.4984 5.32397 12.2517 5.32397C12.005 5.32397 11.8406 5.40621 11.6761 5.57067L6.98888 10.1757C6.65996 10.5868 6.65996 11.0802 6.98888 11.4914C6.98888 11.4091 6.98888 11.4091 6.98888 11.4914Z'
            fill='#05e9c7'
          />
          <path
            d='M12.9889 11.4914L17.6761 16.0963C18.005 16.4253 18.4984 16.4253 18.8273 16.0963C19.1563 15.7674 19.1563 15.274 18.8273 14.9451L14.798 10.8335L18.8273 6.72191C19.1563 6.39299 19.1563 5.8996 18.8273 5.57067C18.6629 5.40621 18.4984 5.32397 18.2517 5.32397C18.005 5.32397 17.8406 5.40621 17.6761 5.57067L12.9889 10.1757C12.66 10.5868 12.66 11.0802 12.9889 11.4914C12.9889 11.4091 12.9889 11.4091 12.9889 11.4914Z'
            fill='#05e9c7'
          />
        </svg>
      }
      lastPageText={
        <svg
          width='27'
          height='21'
          viewBox='0 0 27 21'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M19.6771 10.1759L14.9899 5.5709C14.661 5.24197 14.1676 5.24197 13.8387 5.5709C13.5097 5.89982 13.5097 6.39321 13.8387 6.72214L17.868 10.8337L13.8387 14.9453C13.5097 15.2742 13.5097 15.7676 13.8387 16.0966C14.0031 16.261 14.1676 16.3433 14.4143 16.3433C14.661 16.3433 14.8255 16.261 14.9899 16.0966L19.6771 11.4916C20.0061 11.0804 20.0061 10.587 19.6771 10.1759C19.6771 10.2581 19.6771 10.2581 19.6771 10.1759Z'
            fill='#05e9c7'
          />
          <path
            d='M13.6771 10.1759L8.98992 5.5709C8.66099 5.24197 8.1676 5.24197 7.83867 5.5709C7.50975 5.89982 7.50975 6.39321 7.83867 6.72214L11.868 10.8337L7.83867 14.9453C7.50975 15.2742 7.50975 15.7676 7.83867 16.0966C8.00314 16.261 8.1676 16.3433 8.4143 16.3433C8.66099 16.3433 8.82546 16.261 8.98992 16.0966L13.6771 11.4916C14.0061 11.0804 14.0061 10.587 13.6771 10.1759C13.6771 10.2581 13.6771 10.2581 13.6771 10.1759Z'
            fill='#05e9c7'
          />
        </svg>
      }
      prevPageText={
        <svg
          width='7'
          height='12'
          viewBox='0 0 7 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M0.617789 6.49136L5.305 11.0963C5.63393 11.4253 6.12732 11.4253 6.45625 11.0963C6.78518 10.7674 6.78518 10.274 6.45625 9.94509L2.42689 5.8335L6.45625 1.72191C6.78518 1.39299 6.78518 0.899597 6.45625 0.57067C6.29178 0.406206 6.12732 0.323975 5.88062 0.323975C5.63393 0.323975 5.46947 0.406206 5.305 0.57067L0.617789 5.17565C0.288862 5.58681 0.288862 6.0802 0.617789 6.49136C0.617789 6.40913 0.617789 6.40913 0.617789 6.49136Z'
            fill='#05e9c7'
          />
        </svg>
      }
      nextPageText={
        <svg
          width='8'
          height='12'
          viewBox='0 0 8 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M7.04432 5.17539L2.35711 0.570409C2.02818 0.241482 1.53479 0.241482 1.20586 0.570409C0.876934 0.899336 0.876934 1.39273 1.20586 1.72165L5.23522 5.83324L1.20586 9.94483C0.876934 10.2738 0.876934 10.7672 1.20586 11.0961C1.37033 11.2605 1.53479 11.3428 1.78148 11.3428C2.02818 11.3428 2.19264 11.2605 2.35711 11.0961L7.04432 6.4911C7.37325 6.07994 7.37325 5.58655 7.04432 5.17539C7.04432 5.25762 7.04432 5.25762 7.04432 5.17539Z'
            fill='#05e9c7'
          />
        </svg>
      }
      onChange={setPage}
    />
  );
};

export default Paging;

import React, { useState, useEffect } from 'react';
import Paging from '../util/Paging';
import styles from './UserMachineLog.module.css';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getMachineLog,
  getMachineLogDetail,
} from '../../network/api/MachineAPI';

const UserMachineLog = () => {
  const navigate = useNavigate();

  const { page } = useParams();
  let intPage = parseInt(page);
  const [dataList, setDataList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    userId: '',
    dogId: '',
    category: '',
    serialNumber: '',
  });

  useEffect(() => {
    getMachineLogInit();
  }, [intPage]);

  const getMachineLogInit = async () => {
    const response = await getMachineLog({
      page: intPage,
      size: 10,
      from: filters.startDate,
      to: filters.endDate,
      userId: filters.userId,
      dogId: filters.dogId,
      category: filters.category,
      serialNumber: filters.serialNumber,
    });
    if (response) {
      if (response === undefined) {
        return;
      }
      try {
        setDataList(response.content);
        setTotalCount(response.totalElements);
      } catch (error) {
        console.error('데이터 불러오기 실패:', error);
      }
    }
  };

  const getMachineLogDetailFunc = async (id) => {
    navigate(`/user-machine-log-detail/${id}`);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === 'serialNumber' && !/^\d*$/.test(value)) {
      return; // 숫자가 아닌 입력은 무시
    }

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = () => {
    getMachineLogInit();
    console.log(filters);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      getMachineLogInit();
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/user-machine-log/${page}`);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.titleText}>서비스 이용내역 관리</div>
        <div className={styles.filterSection}>
          <input
            name='startDate'
            type='date'
            className={styles.selectBox}
            value={filters.startDate}
            onChange={handleFilterChange}
          />
          <div>~</div>
          <input
            name='endDate'
            type='date'
            className={styles.selectBox}
            value={filters.endDate}
            onChange={handleFilterChange}
          />
          <input
            name='userId'
            type='text'
            value={filters.machineName}
            onChange={handleFilterChange}
            onKeyDown={onKeyDown}
            placeholder='이용자 ID 입력'
            className={styles.searchBox}
          />
          <select
            name='category'
            value={filters.district}
            onChange={handleFilterChange}
            className={styles.selectBox}>
            <option value=''>구분</option>
            <option value='자판기'>자판기</option>
            <option value='수거함'>수거함</option>
          </select>
          <input
            name='serialNumber'
            type='text'
            value={filters.serialNumber}
            onChange={handleFilterChange}
            onKeyDown={onKeyDown}
            placeholder='기기번호 입력'
            className={styles.searchBox}
          />
          <button onClick={handleSearch} className={styles.searchButton}>
            검색
          </button>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            <col style={{ minWidth: '70px' }}></col>
            <col style={{ minWidth: '70px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '180px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
            <col style={{ minWidth: '120px' }}></col>
          </colgroup>
          <thead>
            <tr>
              <th>ID</th>
              <th>이용자ID</th>
              <th>이용일시</th>
              <th>지역</th>
              <th>기기명</th>
              <th>일련번호</th>
              <th>기긱종류</th>
            </tr>
          </thead>
          <tbody>
            {dataList.map((item, index) => (
              <tr
                key={index}
                onClick={() => {
                  getMachineLogDetailFunc(item.id);
                }}>
                <td>{item.id}</td>
                <td>{item.userId}</td>
                <td>{item.useTime}</td>
                <td>{item.address}</td>
                <td>{item.machineName}</td>
                <td>{item.serialNumber}</td>
                <td>{item.category}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={intPage}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

UserMachineLog.propTypes = {};

export default UserMachineLog;

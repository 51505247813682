import { baseInstance, authInstance } from '../AxiosClient';

export const getUserList = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`user/get`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getUserListWithSearch = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`user/get/search`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

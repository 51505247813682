import React from 'react';

const Logo = () => {
  return (
    <svg
      width='39'
      height='30'
      viewBox='0 0 39 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.27 6.66323C19.27 6.66323 19.27 6.69323 19.29 6.71323C20.05 8.07323 20.46 9.61323 20.46 11.2032C20.46 14.0932 19.11 16.6332 17.03 18.3332C16.51 18.7632 15.95 19.1332 15.34 19.4332C14.09 20.0632 12.69 20.4632 11.19 20.4632C6.08 20.4632 1.93 16.3032 1.93 11.2032C1.93 6.10323 6.09 1.93323 11.19 1.93323C12.04 1.93323 12.88 2.04323 13.68 2.27323C14.18 2.41323 14.72 2.11323 14.87 1.60323C15.01 1.09323 14.71 0.553235 14.2 0.413235C13.23 0.143235 12.21 0.00323486 11.19 0.00323486C5.02 0.00323486 0 5.02323 0 11.1932V28.4232C0 28.9532 0.43 29.3832 0.96 29.3832C1.49 29.3832 1.92 28.9532 1.92 28.4232V17.4632C3.93 20.4332 7.33 22.3832 11.18 22.3832C17.35 22.3832 22.37 17.3632 22.37 11.1932C22.37 8.49323 21.4 5.89323 19.63 3.86323C19.36 3.54323 19.06 3.25323 18.75 2.97323C18.75 2.97323 18.18 4.60323 19.26 6.66323H19.27Z'
        fill='#05C3A7'
      />
      <path
        d='M38.39 11.9532C38.1 11.0232 37.42 10.2632 36.52 9.87325L34.31 8.92325C33.88 8.73325 33.7 8.31325 33.63 8.08325C32.19 3.07325 27.53 -0.236751 22.33 0.0132488C16.57 0.303249 11.8 5.23325 11.72 11.0132C11.69 13.1132 12.26 15.1332 13.3 16.8832C13.68 17.5232 14.11 18.1232 14.61 18.6832C15.2 18.3332 15.68 17.9132 16.09 17.4432C19.13 13.9932 17.77 7.87325 17.77 7.87325C17.58 12.2932 16.28 14.6132 14.92 15.8432C14.08 14.4032 13.62 12.7532 13.64 11.0332C13.68 8.68325 14.66 6.52325 16.21 4.89325C16.21 4.89325 16.26 4.84325 16.32 4.78325C16.35 4.75325 16.39 4.71325 16.42 4.68325C16.57 4.53325 16.79 4.33325 17.05 4.11325C17.06 4.10325 17.08 4.08325 17.09 4.07325C17.57 3.66325 18.18 3.20325 18.75 2.96325C19.87 2.38325 21.11 2.00325 22.42 1.94325C26.72 1.70325 30.58 4.47325 31.77 8.61325C32.05 9.57325 32.69 10.3232 33.53 10.6932L35.09 11.3732C34.9 12.0932 34.84 13.3132 36.16 14.0332L34.01 17.4532C33.6 18.1132 33.01 18.6132 32.3 18.9232C31.04 19.4632 28.8 20.1532 25.3 20.3832C25.18 20.3832 25.06 20.4232 24.94 20.4832C24.46 20.7132 24.26 21.2932 24.5 21.7732L26.94 26.7632C27.72 28.3632 29.38 29.4032 31.16 29.4032H34.46C34.99 29.4032 35.42 28.9732 35.42 28.4432C35.42 27.9132 34.99 27.4832 34.46 27.4832H31.16C30.11 27.4832 29.13 26.8732 28.67 25.9332L26.85 22.2032C29.84 21.8832 31.86 21.2332 33.08 20.7132C34.15 20.2532 35.04 19.4932 35.66 18.5032L38.02 14.7532C38.54 13.9232 38.67 12.9132 38.38 11.9832L38.39 11.9532Z'
        fill='#05C3A7'
      />
      <path
        d='M30.68 12.0333C31.19 11.6833 31.32 10.9933 30.97 10.4833C30.62 9.97326 29.93 9.84326 29.42 10.1933C28.91 10.5433 28.78 11.2333 29.13 11.7433C29.48 12.2533 30.17 12.3833 30.68 12.0333Z'
        fill='#05C3A7'
      />
    </svg>
  );
};

export default Logo;

import { baseInstance, authInstance } from '../AxiosClient';

export const getEventSchedule = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`eventSchedule/get`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getEventScheduleSearch = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`eventSchedule/get/search`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const registerEventSchedule = async (eventSchedule) => {
  try {
    const { data } = await authInstance.post(
      `eventSchedule/register`,
      eventSchedule
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyEventSchedule = async (eventSchedule) => {
  try {
    const { data } = await authInstance.post(
      `eventSchedule/modify`,
      eventSchedule
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyEventScheduleImage = async (eventSchedule) => {
  try {
    const { data } = await authInstance.post(
      `eventSchedule/modify/image`,
      eventSchedule
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getEventScheduleByCategory = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`eventSchedule/get/category`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getEventScheduleDetail = async (id) => {
  try {
    const { data } = await authInstance.get(`eventSchedule/get/detail`, {
      params: id,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteEventSchedule = async (id) => {
  try {
    const { data } = await authInstance.delete(`eventSchedule/delete`, {
      params: id,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

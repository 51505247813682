import React, { useState, useEffect } from 'react';
import Paging from '../util/Paging';
import styles from './VendingMachine.module.css';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { getMachine } from '../../network/api/MachineAPI';
import { regionDistricts } from '../../utils/staticData';
import MachineDialog from '../util/MachineDialog';

const VendingMachine = () => {
  const navigate = useNavigate();
  const [selectData, setSelectData] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState({
    city: '',
    district: '',
    category: '',
    cbStatus: '',
    vmStatus: '',
    cbReleaseStatus: '',
    vmReleaseStatus: '',
    machineName: '',
  });
  const [isDialogOpen, setIsDialogOpen] = useState({
    isRegister: false,
    isOpen: false,
  });

  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getPooPlaceInit();
  }, [intPage]);

  const getPooPlaceInit = async () => {
    const response = await getMachine({
      page: intPage,
      size: 10,
      category: filters.category,
      machineName: filters.machineName,
      city: filters.city,
      district: filters.district,
      cbStatus: filters.cbStatus,
      vmStatus: filters.vmStatus,
      cbReleaseStatus: filters.cbReleaseStatus,
      vmReleaseStatus: filters.vmReleaseStatus,
    });
    if (response) {
      if (response === undefined) {
        return;
      }
      try {
        setDataList(response.content);
        setTotalCount(response.totalElements);
      } catch (error) {
        console.error('데이터 불러오기 실패:', error);
      }
    }
  };

  const machineDialogHandler = (isRegister, item) => {
    if (!isRegister) {
      setSelectData(item);
    }
    setIsDialogOpen({ isRegister: isRegister, isOpen: true });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = () => {
    // 검색 버튼 클릭 시 필터링 로직 추가
    getPooPlaceInit();
    console.log(filters);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      getPooPlaceInit();
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/machine/${page}`);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        {isDialogOpen.isOpen ? (
          <MachineDialog
            category={'자판기'}
            selectData={selectData}
            isRegisterDialog={isDialogOpen.isRegister}
            setIsDialogOpen={setIsDialogOpen}
          />
        ) : null}
        <div className={styles.titleText}>기기 관리</div>
        <div className={styles.filterSection}>
          <select
            name='city'
            value={filters.city}
            onChange={handleFilterChange}
            className={styles.selectBox}>
            <option value=''>지역</option>
            {Object.keys(regionDistricts).map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
          <select
            name='district'
            value={filters.district}
            onChange={handleFilterChange}
            className={styles.selectBox}>
            <option value=''>시/군/구</option>
            {filters.city &&
              regionDistricts[filters.city].map((district) => (
                <option key={district} value={district}>
                  {district}
                </option>
              ))}
          </select>
          <select
            name='category'
            value={filters.category}
            onChange={handleFilterChange}
            className={styles.selectBox}>
            <option value=''>구분</option>
            <option value='tmach'>일체형</option>
            <option value='vmach'>자판기</option>
            <option value='cbobx'>수거함</option>
          </select>
          <select
            name='vmStatus'
            value={filters.vmStatus}
            onChange={handleFilterChange}
            className={styles.selectBox}>
            <option value=''>자판기 상태</option>
            <option value='active'>사용가능</option>
            <option value='maintenance'>점검중</option>
            <option value='disable'>사용불가</option>
          </select>
          <select
            name='cbStatus'
            value={filters.cbStatus}
            onChange={handleFilterChange}
            className={styles.selectBox}>
            <option value=''>수거함 상태</option>
            <option value='active'>사용가능</option>
            <option value='maintenance'>점검중</option>
            <option value='disable'>사용불가</option>
          </select>
          <select
            name='releaseStatus'
            value={filters.releaseStatus}
            onChange={handleFilterChange}
            className={styles.selectBox}>
            <option value=''>노출여부</option>
            <option value={true}>노출</option>
            <option value={false}>비노출</option>
          </select>
          <input
            type='text'
            name='machineName'
            value={filters.machineName}
            onChange={handleFilterChange}
            onKeyDown={onKeyDown}
            placeholder='검색어 입력'
            className={styles.searchBox}
          />
          <button onClick={handleSearch} className={styles.searchButton}>
            검색
          </button>
        </div>
        <div className={styles.listContainer}>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <div
            className={styles.registerButton}
            onClick={() => {
              machineDialogHandler(true);
            }}>
            등록
          </div>
        </div>
        <table className={styles.customTable}>
          <colgroup>
            <col style={{ minWidth: '70px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
            <col style={{ minWidth: '150px' }}></col>
            <col style={{ minWidth: '200px' }}></col>
            <col style={{ minWidth: '100px' }}></col>
            <col style={{ minWidth: '80px' }}></col>
            <col style={{ minWidth: '80px' }}></col>
          </colgroup>
          <thead>
            <tr>
              <th>ID</th>
              <th>기기구분</th>
              <th>지역</th>
              <th>일련번호</th>
              <th>기기명</th>
              <th>상태</th>
              <th>노출여부</th>
              <th>잔여량</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {dataList.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>
                  {item.category === 'tmach'
                    ? '일체형'
                    : item.category === 'vmach'
                    ? '자판기'
                    : '수거함'}
                </td>
                <td>{item.address}</td>
                <td>{item.serialNumber}</td>
                <td>{item.machineName}</td>
                <td>
                  {item.vmStatus !== null
                    ? item.vmStatus === 'active'
                      ? '사용가능'
                      : item.vmStatus === 'maintenance'
                      ? '점검중'
                      : '사용불가'
                    : '-'}{' '}
                  /{' '}
                  {item.cbStatus !== null
                    ? item.cbStatus === 'active'
                      ? '사용가능'
                      : item.cbStatus === 'maintenance'
                      ? '점검중'
                      : '사용불가'
                    : '-'}
                </td>
                <td>{item.releaseStatus ? '노출' : '비노출'}</td>
                <td>{item.remainAmount}</td>
                <td className={styles.modifyTd}>
                  <a
                    onClick={() => {
                      machineDialogHandler(false, item);
                    }}
                    className={styles.modifyButton}>
                    수정
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.pagingContainer}>
          <Paging
            page={intPage}
            countPerPage={10}
            pageRangeDisplayed={5}
            count={totalCount}
            setPage={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

VendingMachine.propTypes = {
  category: PropTypes.string,
};

export default VendingMachine;

import React, { useEffect, useState, useRef } from 'react';
import DaumPostcode from 'react-daum-postcode';
import styles from '../poo/PooRegisterDialog.module.css';
import PropTypes from 'prop-types';
import altImage from '../../resource/images/alt_image.png';
import MiniMap from '../util/MiniMap';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import { modifyPlace } from '../../network/api/PlaceAPI';
import TextareaAutosize from 'react-textarea-autosize';
import CloseButton from '../../resource/images/svg/CloseButton';
import { compressImage } from '../../utils/compressImage';

let imageFileBlobList = [];

const PlaceModifyDialog = ({ place, setIsModifyDialogOpen }) => {
  const inputRef = useRef(null);
  const inputTextRef = useRef(null);
  const postPopupArea = useRef(null);
  const [category, setCategory] = useState(place.category);
  const [imageList, setImageList] = useState(
    place.imageUrlList !== null ? place.imageUrlList : []
  );
  const [placeName, setPlaceName] = useState(place.name);
  const [linkUrl, setLinkLink] = useState(place.homepageUrl);
  const [phoneNumber, setPhoneNumber] = useState(place.phone);
  const [isAllTime, setIsAllTime] = useState(
    place.openHour.every((item) => item === '00:00 ~ 00:00')
  );
  const changeTime = (time) => {
    const currentDate = new Date();
    const [hours, minutes] = time.split(':');
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      hours,
      minutes
    );
    return newDate;
  };
  const [openDayList, setOpenDayList] = useState([
    {
      day: '월',
      isDayOff: place.openHour[0] === '휴무',
      startTime:
        place.openHour[0] !== '휴무'
          ? changeTime(place.openHour[0].split(' ~ ')[0])
          : '',
      finishTime:
        place.openHour[0] !== '휴무'
          ? changeTime(place.openHour[0].split(' ~ ')[1])
          : '',
    },
    {
      day: '화',
      isDayOff: place.openHour[1] === '휴무',
      startTime:
        place.openHour[1] !== '휴무'
          ? changeTime(place.openHour[1].split(' ~ ')[0])
          : '',
      finishTime:
        place.openHour[1] !== '휴무'
          ? changeTime(place.openHour[1].split(' ~ ')[1])
          : '',
    },
    {
      day: '수',
      isDayOff: place.openHour[2] === '휴무',
      startTime:
        place.openHour[2] !== '휴무'
          ? changeTime(place.openHour[2].split(' ~ ')[0])
          : '',
      finishTime:
        place.openHour[2] !== '휴무'
          ? changeTime(place.openHour[2].split(' ~ ')[1])
          : '',
    },
    {
      day: '목',
      isDayOff: place.openHour[3] === '휴무',
      startTime:
        place.openHour[3] !== '휴무'
          ? changeTime(place.openHour[3].split(' ~ ')[0])
          : '',
      finishTime:
        place.openHour[3] !== '휴무'
          ? changeTime(place.openHour[3].split(' ~ ')[1])
          : '',
    },
    {
      day: '금',
      isDayOff: place.openHour[4] === '휴무',
      startTime:
        place.openHour[4] !== '휴무'
          ? changeTime(place.openHour[4].split(' ~ ')[0])
          : '',
      finishTime:
        place.openHour[4] !== '휴무'
          ? changeTime(place.openHour[4].split(' ~ ')[1])
          : '',
    },
    {
      day: '토',
      isDayOff: place.openHour[5] === '휴무',
      startTime:
        place.openHour[5] !== '휴무'
          ? changeTime(place.openHour[5].split(' ~ ')[0])
          : '',
      finishTime:
        place.openHour[5] !== '휴무'
          ? changeTime(place.openHour[5].split(' ~ ')[1])
          : '',
    },
    {
      day: '일',
      isDayOff: place.openHour[6] === '휴무',
      startTime:
        place.openHour[6] !== '휴무'
          ? changeTime(place.openHour[6].split(' ~ ')[0])
          : '',
      finishTime:
        place.openHour[6] !== '휴무'
          ? changeTime(place.openHour[6].split(' ~ ')[1])
          : '',
    },
  ]);
  const [isPostPopupOpen, setIsPostPopupOpen] = useState(false);
  const [isNaverPopUpOpen, setIsNaverPopUpOpen] = useState(false);
  const [roadAddress, setRoadAddress] = useState(place.roadAddress);
  const [jibunAddress, setJibunAddress] = useState(place.jibunAddress);
  const [placePoint, setPlacePoint] = useState({
    latitude: place.latitude,
    longitude: place.longitude,
  });
  const handleInputChange = (value) => {
    let plainText = value.replace(/<\/p>\s*<p>/gi, '\n');
    plainText = plainText.replace(/<br\s*[/]?>/gi, '\n');
    plainText = plainText.replace(/<\/?p>/g, '');
    return plainText;
  };
  const [inputNotice, setInputNotice] = useState(
    handleInputChange(place.notice)
  );
  const [outputNotice, setOutputNotice] = useState(place.notice);
  const [noticeTextCount, setNoticeTextCount] = useState(inputNotice.length);

  useEffect(() => {
    if (
      place.imageUrlList !== null &&
      place.imageUrlList.length !== 0 &&
      place.imageUrlList[0] !== ''
    ) {
      for (const urlString of place.imageUrlList) {
        imageFileBlobList.push(urlString);
      }
    }
    return () => {
      imageFileBlobList.length = 0;
    };
  }, []);

  useEffect(() => {
    function handleClickPopupOutside(e) {
      if (postPopupArea.current && !postPopupArea.current.contains(e.target)) {
        closePostCode();
      }
    }
    document.addEventListener('mousedown', handleClickPopupOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickPopupOutside);
    };
  }, [postPopupArea]);

  const placeNameInputHandler = (e) => {
    setPlaceName(e.target.value);
  };

  const handleFileInputChange = async (event) => {
    if (imageFileBlobList.length >= 20) {
      alert('이미지는 20개 까지만 등록이 가능합니다.');
    } else {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          alert('파일 크기는 20MB 이하여야 합니다.');
        } else {
          const compressedImage = await compressImage(file);
          const fileURL = URL.createObjectURL(compressedImage);
          imageFileBlobList.push(compressedImage);
          setImageList((prevList) => [...prevList, fileURL]);
        }
      }
    }
    event.target.value = '';
  };

  const deleteImage = (index) => {
    const updatedImageList = imageList.filter(
      (_, listIndex) => listIndex !== index
    );

    imageFileBlobList = imageFileBlobList.filter(
      (item, fileIndex) => fileIndex !== index
    );

    setImageList(updatedImageList);
  };

  const phoneInputHandler = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setPhoneNumber(e.target.value);
    }
  };

  const linkUrlInputHandler = (e) => {
    setLinkLink(e.target.value);
  };

  const handleStartTimeChange = (date, index) => {
    const updatedList = [...openDayList];
    updatedList[index] = { ...updatedList[index], startTime: date };

    setOpenDayList(updatedList);
  };

  const handleFinishTimeChange = (date, index) => {
    const updatedList = [...openDayList];
    updatedList[index] = { ...updatedList[index], finishTime: date };

    setOpenDayList(updatedList);
  };

  const noticeInputHandler = (e) => {
    setNoticeTextCount(
      e.target.value.substring(0, 10000).replace(/<br\s*\/?>/gm, '\n').length
    );
    const newText = e.target.value;
    setInputNotice(newText.substring(0, 10000));

    const paragraphs = newText.split('\n').map((line) => `<p>${line}</p>`);
    const resultString = paragraphs.join('');
    const final = convertToBrString(resultString);
    setOutputNotice(final);
  };

  const convertToBrString = (inputString) => {
    const regex = /(<p><\/p>)+/g;

    const resultString = inputString.replace(regex, (match) => {
      const blockCount = match.length / 7;
      if (blockCount > 1) {
        const brTags = Array(blockCount - 1)
          .fill('<br>')
          .join('');
        return `<p>${brTags}</p>`;
      } else {
        return match;
      }
    });

    return resultString;
  };

  const openPostCode = () => {
    setIsPostPopupOpen(true);
  };

  const closePostCode = () => {
    setIsPostPopupOpen(false);
  };

  const PostComplete = async (data) => {
    closePostCode();
    openNaverMapPopUp(data.address, data.jibunAddress);
  };

  const openNaverMapPopUp = (roadAddress, jibunAddress) => {
    if (roadAddress) {
      if (roadAddress !== '') {
        setRoadAddress(roadAddress);
        setJibunAddress(jibunAddress);
        setIsNaverPopUpOpen(true);
      } else {
        alert('우편번호 찾기를 진행해 주세요!');
      }
    }
  };

  const changeMinHeight = (height) => {
    if (inputTextRef.current) {
      if (height < 55) {
        inputTextRef.current.style.minHeight = '55px';
      } else {
        inputTextRef.current.style.minHeight = `${height}px`;
      }
    }
  };

  const openCamera = () => inputRef.current && inputRef.current.click();

  const getTimeString = (startTime, finishTime) => {
    const startHours = startTime.getHours().toString().padStart(2, '0');
    const startMinutes = startTime.getMinutes().toString().padStart(2, '0');
    const finishHours = finishTime.getHours().toString().padStart(2, '0');
    const finishMinutes = finishTime.getMinutes().toString().padStart(2, '0');

    return `${startHours}:${startMinutes} ~ ${finishHours}:${finishMinutes}`;
  };

  const registerButtonClick = async () => {
    if (
      placeName !== '' &&
      category !== '' &&
      placePoint.latitude !== null &&
      placePoint.longitude !== null
    ) {
      let isArrayEqual = false;
      if (imageList !== null) {
        isArrayEqual =
          JSON.stringify(place.imageUrlList) === JSON.stringify(imageList);
      }

      let openHour = [];
      if (isAllTime) {
        openHour = [
          '00:00 ~ 00:00',
          '00:00 ~ 00:00',
          '00:00 ~ 00:00',
          '00:00 ~ 00:00',
          '00:00 ~ 00:00',
          '00:00 ~ 00:00',
          '00:00 ~ 00:00',
        ];
      } else {
        for (const openDay of openDayList) {
          if (openDay.isDayOff) {
            openHour.push('휴무');
          } else {
            if (openDay.startTime !== '' && openDay.finishTime !== '') {
              openHour.push(
                getTimeString(openDay.startTime, openDay.finishTime)
              );
            } else {
              alert('필수항목들을 입력해 주세요');
              return;
            }
          }
        }
      }

      let isDayArrayEqual =
        JSON.stringify(place.openHour) === JSON.stringify(openHour);

      if (
        isArrayEqual &&
        isDayArrayEqual &&
        category === place.category &&
        linkUrl === place.homepageUrl &&
        placeName === place.name &&
        phoneNumber === place.phone &&
        placePoint.latitude === place.latitude &&
        placePoint.longitude === place.longitude &&
        jibunAddress === place.jibunAddress &&
        roadAddress === place.roadAddress &&
        outputNotice === place.notice
      ) {
        return;
      }
      const formData = new FormData();

      for (const fileBlob of imageFileBlobList) {
        if (typeof fileBlob !== 'string') {
          formData.append('multipartFiles', fileBlob);
        }
      }

      const uploaderString = JSON.stringify({
        id: place.id,
        category: category,
        name: placeName,
        latitude: placePoint.latitude,
        longitude: placePoint.longitude,
        roadAddress: roadAddress,
        jibunAddress: jibunAddress,
        openDay: ['월', '화', '수', '목', '금', '토', '일'],
        openHour: openHour,
        notice: outputNotice,
        phone: phoneNumber,
        homepageUrl: linkUrl,
        imageUrlList: imageList,
      });

      formData.append(
        'place',
        new Blob([uploaderString], { type: 'application/json' })
      );
      const response = await modifyPlace(formData);
      if (response === 'success') {
        window.location.reload(true);
      } else if (response === 'over') {
        alert('등록실패(이미지 20개 제한)');
      } else {
        alert('등록실패');
      }
    } else {
      alert('필수항목들을 입력해 주세요');
    }
  };

  const handleToggleDayOff = (day) => {
    setOpenDayList(
      openDayList.map((openDay) => {
        if (openDay.day === day) {
          return { ...openDay, isDayOff: !openDay.isDayOff };
        } else {
          return openDay;
        }
      })
    );
  };

  return (
    <div className={styles.wrapper}>
      {isNaverPopUpOpen ? (
        <MiniMap
          roadAddress={roadAddress}
          setPlacePoint={setPlacePoint}
          setIsNaverPopUpOpen={setIsNaverPopUpOpen}
          setRoadAddress={setRoadAddress}
          setJibunAddress={setJibunAddress}
        />
      ) : null}
      {isPostPopupOpen ? (
        <div className={styles.postPopupContainer} style={{ overflow: 'auto' }}>
          <div
            className={styles.postInnerPopup}
            ref={postPopupArea}
            style={{ margin: 'auto' }}>
            <DaumPostcode
              className={styles.daumPostPopup}
              onComplete={PostComplete} // 값을 선택할 경우 실행되는 이벤트
              autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
            />
            <div
              className={styles.postPopupCloseButton}
              onClick={() => {
                closePostCode();
              }}>
              닫기
            </div>
          </div>
        </div>
      ) : null}
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsModifyDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          <div className={styles.inputTitle}>카테고리</div>
          <div className={styles.genderRadioGroup}>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('호텔');
              }}>
              {category === '호텔' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>호텔</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('공원');
              }}>
              {category === '공원' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>공원</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('놀이터');
              }}>
              {category === '놀이터' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>놀이터</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('미용');
              }}>
              {category === '미용' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>미용</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('병원');
              }}>
              {category === '병원' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>병원</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('쇼핑');
              }}>
              {category === '쇼핑' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>쇼핑</div>
            </div>
          </div>
          <div className={styles.genderRadioGroup}>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('숙박');
              }}>
              {category === '숙박' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>숙박</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('식당');
              }}>
              {category === '식당' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>식당</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('카페');
              }}>
              {category === '카페' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>카페</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('캠핑');
              }}>
              {category === '캠핑' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>캠핑</div>
            </div>
            <div
              className={styles.radioBox}
              onClick={() => {
                setCategory('기타');
              }}>
              {category === '기타' ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>기타</div>
            </div>
          </div>
          <div className={styles.inputTitle}>장소이름 (필수, 최대 50자)</div>
          <input
            type='text'
            className={styles.inputId}
            placeholder='장소이름'
            maxLength={50}
            onChange={placeNameInputHandler}
            value={placeName}
          />
          <div className={styles.inputTitle}>이미지 (최대 20개)</div>
          <div className={styles.imageContainer}>
            {imageList !== null &&
            imageList.length !== 0 &&
            imageList[0] !== '' ? (
              imageList.map((image, index) => (
                <>
                  <div className={styles.imageBoxWrapper}>
                    <input
                      id='input-store-image-upload'
                      type='file'
                      ref={inputRef}
                      accept='.jpg, .jpeg, .png'
                      onChange={handleFileInputChange}
                      style={{
                        position: 'absolute',
                        left: '-1000px',
                        visibility: 'hidden',
                      }}
                    />
                    <img
                      className={styles.afterImageContainer}
                      onClick={openCamera}
                      src={image}
                    />
                    <div
                      className={styles.deleteButton}
                      onClick={() => {
                        deleteImage(index);
                      }}>
                      삭제
                    </div>
                  </div>

                  {imageList.length - 1 === index ? (
                    <>
                      <input
                        id='input-store-image-upload'
                        type='file'
                        ref={inputRef}
                        accept='.jpg, .jpeg, .png'
                        onChange={handleFileInputChange}
                        style={{
                          position: 'absolute',
                          left: '-1000px',
                          visibility: 'hidden',
                        }}
                      />
                      <label
                        htmlFor='input-store-image-upload'
                        className={styles.imageUploadButton}>
                        <img className={styles.profileBox} src={altImage} />
                      </label>{' '}
                    </>
                  ) : null}
                </>
              ))
            ) : (
              <>
                <input
                  id='input-store-image-upload'
                  type='file'
                  ref={inputRef}
                  accept='.jpg, .jpeg, .png'
                  onChange={handleFileInputChange}
                  style={{
                    position: 'absolute',
                    left: '-1000px',
                    visibility: 'hidden',
                  }}
                />
                <label
                  htmlFor='input-store-image-upload'
                  className={styles.imageUploadButton}>
                  <img className={styles.profileBox} src={altImage} />
                </label>
              </>
            )}
          </div>
          <div className={styles.inputTitle}>위치등록 (필수)</div>
          {placePoint.latitude !== null && placePoint.longitude !== null ? (
            <div
              className={styles.closeButton2}
              onClick={() => {
                openPostCode();
              }}>
              등록완료
            </div>
          ) : (
            <div
              className={styles.loginButton2}
              onClick={() => {
                openPostCode();
              }}>
              위치 등록
            </div>
          )}
          <div className={styles.inputTitle}>도로명 주소</div>
          <div className={styles.defaultInput}>
            {roadAddress === '' ? '-' : roadAddress}
          </div>
          <div className={styles.inputTitle}>지번 주소</div>
          <div className={styles.defaultInput}>
            {jibunAddress === '' ? '-' : jibunAddress}
          </div>
          <div className={styles.inputTitle}>링크</div>
          <input
            type='text'
            className={styles.inputId}
            placeholder='링크'
            onChange={linkUrlInputHandler}
            value={linkUrl}
          />
          <div className={styles.inputTitle}>연락처</div>
          <input
            type='tel'
            className={styles.inputId}
            placeholder="연락처('-'없이 입력)"
            maxLength={12}
            onChange={phoneInputHandler}
            value={phoneNumber}
          />
          <div className={styles.inputTitleDay}>영업시간 (필수)</div>
          <div
            className={styles.allTimeRadioBox}
            onClick={() => {
              setIsAllTime(!isAllTime);
            }}>
            {isAllTime ? (
              <div className={styles.selectedRadio}>
                <div className={styles.selectedRadioInner} />
              </div>
            ) : (
              <div className={styles.unselectedRadio} />
            )}
            <div className={styles.radioText}>연중무휴</div>
          </div>
          {isAllTime
            ? null
            : openDayList.map((openDay, index) => (
                <div className='row-box' key={openDay.day}>
                  <div className={styles.dayText}>{openDay.day}</div>
                  <div
                    className={styles.dateRadioBox}
                    onClick={() => {
                      handleToggleDayOff(openDay.day);
                    }}>
                    {openDay.isDayOff ? (
                      <div className={styles.selectedRadio}>
                        <div className={styles.selectedRadioInner} />
                      </div>
                    ) : (
                      <div className={styles.unselectedRadio} />
                    )}
                    <div className={styles.radioText}>휴무</div>
                  </div>
                  {openDay.isDayOff ? (
                    <>
                      <div className={styles.defaultBox}>-</div>
                      <div className={styles.defaultBox}>-</div>
                    </>
                  ) : (
                    <>
                      <DatePicker
                        className={styles.customInputBoxDate}
                        selected={openDay.startTime}
                        onChange={(date) => {
                          handleStartTimeChange(date, index);
                        }}
                        locale={ko}
                        showTimeSelect
                        showTimeSelectOnly
                        placeholderText='시작시간'
                        timeIntervals={15}
                        timeCaption='Time'
                        dateFormat='HH:mm'
                      />
                      <DatePicker
                        className={styles.customInputBoxDate}
                        selected={openDay.finishTime}
                        onChange={(date) => {
                          handleFinishTimeChange(date, index);
                        }}
                        locale={ko}
                        showTimeSelect
                        showTimeSelectOnly
                        placeholderText='종료시간'
                        timeIntervals={15}
                        timeCaption='Time'
                        dateFormat='HH:mm'
                      />
                    </>
                  )}
                </div>
              ))}
          <div className={styles.inputTitle}>안내문구 (최대 10,000자)</div>
          <TextareaAutosize
            maxLength={10000}
            ref={inputTextRef}
            cacheMeasurements
            onHeightChange={(height) => {
              changeMinHeight(height);
            }}
            className={styles.quillBox}
            value={inputNotice}
            placeholder={'안내문구를 입력하세요'}
            onChange={noticeInputHandler}
          />
          <div>{noticeTextCount}/10000</div>
          <div
            className={styles.loginButton}
            onClick={() => {
              registerButtonClick();
            }}>
            내주변 장소 수정
          </div>
        </div>
      </div>
    </div>
  );
};

PlaceModifyDialog.propTypes = {
  place: PropTypes.object,
  setIsModifyDialogOpen: PropTypes.func,
};

export default PlaceModifyDialog;

import React, { useEffect, useState } from 'react';
import styles from './CategoryDialog.module.css';
import PropTypes from 'prop-types';
import {
  registerVisitPlaceCategory,
  modifyVisitPlaceCategory,
} from '../../network/api/VisitPlaceAPI';
import {
  registerParticipatePlaceCategory,
  modifyParticipatePlaceCategory,
} from '../../network/api/WithTogetherAPI';

const CategoryDialog = ({
  where,
  categoryId,
  category,
  isRegisterCategoryDialogOpen,
  setIsRegisterCategoryDialogOpen,
  setIsModifyCategoryDialogOpen,
}) => {
  const [categoryName, setCategoryName] = useState(
    isRegisterCategoryDialogOpen ? '' : category
  );
  const categoryInputHandler = (e) => {
    setCategoryName(e.target.value);
  };

  const registerButtonClick = async () => {
    switch (where) {
      case 'visitPlace':
        {
          const response = await registerVisitPlaceCategory({
            category: categoryName,
          });
          if (response === 'success') {
            window.location.reload(true);
          } else if (response === 'exist') {
            alert('중복된 카테고리 명입니다');
          } else {
            alert('등록실패');
          }
        }
        break;
      case 'participate':
        {
          const response = await registerParticipatePlaceCategory({
            category: categoryName,
          });
          if (response === 'success') {
            window.location.reload(true);
          } else if (response === 'exist') {
            alert('중복된 카테고리 명입니다');
          } else {
            alert('등록실패');
          }
        }
        break;
    }
  };

  const modifyButtonClick = async () => {
    switch (where) {
      case 'visitPlace':
        {
          const response = await modifyVisitPlaceCategory({
            id: categoryId,
            category: categoryName,
          });
          if (response === 'success') {
            window.location.reload(true);
          } else if (response === 'exist') {
            alert('중복된 카테고리 명입니다');
          } else {
            alert('수정실패');
          }
        }
        break;
      case 'participate':
        {
          const response = await modifyParticipatePlaceCategory({
            id: categoryId,
            category: categoryName,
          });
          if (response === 'success') {
            window.location.reload(true);
          } else if (response === 'exist') {
            alert('중복된 카테고리 명입니다');
          } else {
            alert('수정실패');
          }
        }
        break;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.inputTopTitle}>카테고리명</div>
        <input
          type='text'
          className={styles.inputId}
          placeholder='카테고리명'
          onChange={categoryInputHandler}
          value={categoryName}
        />
        <div
          className={styles.loginButton}
          onClick={() => {
            isRegisterCategoryDialogOpen
              ? registerButtonClick()
              : modifyButtonClick();
          }}>
          {isRegisterCategoryDialogOpen ? '등록' : '변경'}
        </div>
        <div
          className={styles.closeButton}
          onClick={() => {
            isRegisterCategoryDialogOpen
              ? setIsRegisterCategoryDialogOpen(false)
              : setIsModifyCategoryDialogOpen(false);
          }}>
          닫기
        </div>
      </div>
    </div>
  );
};

CategoryDialog.propTypes = {
  where: PropTypes.string,
  categoryId: PropTypes.number,
  category: PropTypes.string,
  isRegisterCategoryDialogOpen: PropTypes.bool,
  setIsRegisterCategoryDialogOpen: PropTypes.func,
  setIsModifyCategoryDialogOpen: PropTypes.func,
};

export default CategoryDialog;

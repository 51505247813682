import React, { useState, useEffect } from 'react';
import Paging from '../util/Paging';
import styles from './UserMachineLogDetail.module.css';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { getMachineLogDetail } from '../../network/api/MachineAPI';
import LeftArrow from '../../resource/images/svg/LeftArrow';

const UserMachineLogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});

  const tableColumnData1 = [
    { label: '구분', key: 'category' },
    { label: '이용일시', key: 'useTime' },
    { label: '이용자ID', key: 'userId' },
    { label: '이용자 이름', key: 'name' },
    { label: '이용자 전화번호', key: 'phone' },
    { label: '반려동물 이름', key: 'dogName' },
    { label: '동물등록번호', key: 'animalRegistrationNumber' },
  ];

  const tableColumnData2 = [
    { label: '지역', key: 'address' },
    { label: '기기명', key: 'machineName' },
    { label: '일련번호', key: 'serialNumber' },
    { label: '노출여부', key: 'release' },
    { label: '상태', key: 'status' },
  ];

  useEffect(() => {
    getMachineLogDetailInit();
  }, []);

  const getMachineLogDetailInit = async () => {
    const response = await getMachineLogDetail({ id: id });
    setUserData(response);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.titleBox}>
          <div
            className={styles.LeftArrow}
            onClick={() => {
              navigate(-1);
            }}>
            <LeftArrow />
          </div>
          <div className={styles.titleText}>서비스 이용내역 상세</div>
        </div>
        <div className={styles.tableTitle}>이용정보</div>
        <table className={styles.table}>
          <colgroup>
            <col style={{ width: '200px', minWidth: '200px' }}></col>
            <col style={{ width: '90%', minWidth: '600px' }}></col>
          </colgroup>
          <tbody>
            {tableColumnData1.map((item, index) => (
              <tr key={index}>
                <td className={styles.firstColumn}>{item.label}</td>
                <td>{userData[item.key]}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.tableTitle}>기기정보</div>
        <table className={styles.table}>
          <colgroup>
            <col style={{ width: '200px', minWidth: '200px' }}></col>
            <col style={{ width: '90%', minWidth: '600px' }}></col>
          </colgroup>
          <tbody>
            {tableColumnData2.map((item, index) => (
              <tr key={index}>
                <td className={styles.firstColumn}>{item.label}</td>
                <td>
                  {item.key === 'release'
                    ? userData[item.key]
                      ? 'Y'
                      : 'N'
                    : userData[item.key]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

UserMachineLogDetail.propTypes = {};

export default UserMachineLogDetail;

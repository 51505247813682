import { baseInstance, authInstance } from '../AxiosClient';

export const permitDogStatus = async (id) => {
  try {
    const { data } = await authInstance.post(`dog/permit/status`, id);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const rejectDogStatus = async (id) => {
  try {
    const { data } = await authInstance.post(`dog/reject/status`, id);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getDogList = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`dog/get`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getDogListWithSearch = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`dog/get/search`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

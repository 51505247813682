import {
  LOGIN,
  LOGOUT,
  ADD_DOG,
  UPDATE_DOG,
  UPDATE_USERNAME,
  UPDATE_NAME,
  UPDATE_NICKNAME,
  UPDATE_PHONE,
  UPDATE_PROFILE,
} from '../actions/authActions';

const initialState = {
  isAuthenticated: false,
  user: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case ADD_DOG:
      return {
        ...state,
        user: {
          ...state.user,
          dogList: [...state.user.dogList, action.payload],
        },
      };
    case UPDATE_DOG:
      return {
        ...state,
        user: {
          ...state.user,
          dogList: state.user.dogList.map((dog) => {
            if (dog.id === action.payload.id) {
              return {
                ...dog,
                ...action.payload,
              };
            }
            return dog;
          }),
        },
      };
    case UPDATE_USERNAME:
      return {
        ...state,
        user: {
          ...state.user,
          username: action.payload,
        },
      };
    case UPDATE_NAME:
      return {
        ...state,
        user: {
          ...state.user,
          name: action.payload,
        },
      };
    case UPDATE_NICKNAME:
      return {
        ...state,
        user: {
          ...state.user,
          nickname: action.payload,
        },
      };
    case UPDATE_PHONE:
      return {
        ...state,
        user: {
          ...state.user,
          phone: action.payload,
        },
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          profileUrl: action.payload,
        },
      };
    default:
      return state;
  }
};

export default authReducer;

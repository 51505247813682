import React from 'react';

const LogoText = () => {
  return (
    <svg
      width='56'
      height='15'
      viewBox='0 0 56 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.17 3.85H5.68V2.75C5.68 2.51 5.65 2.36 5.59 2.29C5.53 2.22 5.44 2.17 5.32 2.13C5.14 2.07 5.01 2.01 4.92 1.97C4.83 1.92 4.79 1.84 4.79 1.71C4.79 1.44 4.97 1.3 5.34 1.3H6.3C6.78 1.3 7.02 1.54 7.02 2.02V7.71C7.02 8.14 6.82 8.35 6.42 8.35H1.73C1.57 8.47 1.38 8.54 1.15 8.54H0.84V3.28C0.84 3.01 0.82 2.84 0.77 2.76C0.72 2.69 0.64 2.63 0.53 2.6C0.41 2.57 0.28 2.52 0.17 2.47C0.06 2.41 0 2.32 0 2.18C0 1.91 0.18 1.77 0.53 1.77H1.47C1.94 1.77 2.17 2.01 2.17 2.49V3.85ZM5.68 4.96H2.17V6.89C2.17 7.02 2.17 7.13 2.15 7.23H5.67V4.96H5.68ZM11.08 14.47H5.39C4.33 14.47 3.79 14.02 3.79 13.11V11.61C3.79 11.31 3.74 11.11 3.64 11.02C3.54 10.93 3.37 10.86 3.12 10.79C2.88 10.72 2.72 10.65 2.64 10.6C2.56 10.55 2.52 10.45 2.52 10.32C2.52 10.04 2.71 9.9 3.1 9.9H4.42C4.9 9.9 5.14 10.16 5.14 10.67V13.02C5.14 13.24 5.25 13.35 5.47 13.35H11.09C11.51 13.35 11.72 13.54 11.72 13.91C11.72 14.28 11.51 14.47 11.08 14.47ZM8.87 0H10.3C10.8 0 11.05 0.230002 11.05 0.690002V4.36H13.2C13.62 4.36 13.83 4.55 13.83 4.92C13.83 5.29 13.62 5.48 13.2 5.48H11.05V9.74C11.05 10.68 10.71 11.15 10.02 11.15H9.69V1.69C9.69 1.39 9.64 1.19 9.53 1.1C9.43 1.01 9.26 0.940003 9.03 0.890003C8.8 0.840003 8.62 0.780001 8.49 0.720001C8.36 0.660001 8.29 0.560002 8.29 0.420002C8.29 0.140002 8.48 0 8.87 0Z'
        fill='#ffffff'
      />
      <path
        d='M14.73 5.29001H19.66V3.11001H15.19C14.77 3.11001 14.56 2.92001 14.56 2.55001C14.56 2.18001 14.76 2.00001 15.17 2.00001H20.32C20.77 2.00001 20.99 2.25001 20.99 2.74001V5.56001C20.99 6.10001 20.76 6.37001 20.29 6.37001H16.44V8.27001C16.44 8.50001 16.56 8.61001 16.8 8.61001H21.41C21.83 8.61001 22.04 8.79001 22.04 9.16001C22.04 9.53001 21.83 9.72001 21.4 9.72001H16C15.39 9.72001 15.09 9.39001 15.09 8.72001V6.83001C15.09 6.38001 14.93 6.14001 14.62 6.11001C14.52 6.10001 14.43 6.06001 14.35 5.99001C14.28 5.92001 14.24 5.82001 14.24 5.68001C14.24 5.42001 14.4 5.29001 14.73 5.29001ZM24.25 13.72V7.91001H22.1C21.7 7.91001 21.51 7.72001 21.51 7.35001C21.51 6.99001 21.71 6.82001 22.1 6.82001H24.25V4.67001H22.1C21.7 4.67001 21.51 4.48001 21.51 4.11001C21.51 3.76001 21.71 3.58001 22.1 3.58001H24.25V1.70001C24.25 1.40001 24.19 1.20001 24.08 1.10001C23.97 1.01001 23.79 0.930009 23.55 0.880009C23.32 0.830009 23.15 0.770009 23.03 0.720009C22.92 0.660009 22.86 0.570008 22.86 0.430008C22.86 0.150008 23.05 0.0100098 23.44 0.0100098H24.87C25.37 0.0100098 25.62 0.240008 25.62 0.700008V12.29C25.62 13.25 25.28 13.73 24.59 13.73H24.26L24.25 13.72Z'
        fill='#ffffff'
      />
      <path
        d='M30.27 4.46001H36.72V2.50001H30.77C30.4 2.50001 30.2 2.31001 30.14 1.94001C30.19 1.57001 30.39 1.39001 30.74 1.39001H37.45C37.88 1.39001 38.09 1.64001 38.09 2.13001V4.73001C38.09 5.27001 37.86 5.54001 37.4 5.54001H32V7.34001C32 7.57001 32.12 7.68001 32.36 7.68001H37.92C38.35 7.68001 38.56 7.86001 38.56 8.23001C38.56 8.60001 38.34 8.78001 37.9 8.78001H35.03V10.85H40.39C40.83 10.85 41.05 11.03 41.05 11.4C41.05 11.77 40.83 11.96 40.39 11.96H28.23C27.84 11.96 27.65 11.77 27.65 11.4C27.65 11.03 27.84 10.85 28.23 10.85H33.67V8.78001H31.56C30.94 8.78001 30.64 8.45001 30.64 7.78001V5.99001C30.64 5.54001 30.48 5.30001 30.17 5.27001C30.07 5.26001 29.98 5.22001 29.9 5.15001C29.83 5.08001 29.79 4.98001 29.79 4.84001C29.79 4.58001 29.95 4.45001 30.28 4.45001L30.27 4.46001Z'
        fill='#ffffff'
      />
      <path
        d='M54.5 8.69H49.39V10.23C49.39 11.25 49.07 11.76 48.42 11.76H48.04V8.69H42.32C41.93 8.69 41.74 8.5 41.74 8.13C41.74 7.76 41.93 7.58 42.32 7.58H54.49C54.92 7.58 55.13 7.76 55.13 8.13C55.13 8.5 54.92 8.69 54.49 8.69H54.5ZM52.07 14.47H46.34C45.27 14.47 44.74 14.02 44.74 13.11V11.97C44.74 11.67 44.69 11.47 44.58 11.38C44.47 11.29 44.3 11.22 44.07 11.15C43.83 11.08 43.67 11.01 43.58 10.96C43.5 10.91 43.46 10.81 43.46 10.68C43.46 10.4 43.66 10.26 44.06 10.26H45.36C45.86 10.26 46.11 10.52 46.11 11.03V13.02C46.11 13.24 46.21 13.35 46.42 13.35H52.09C52.51 13.35 52.72 13.54 52.72 13.91C52.72 14.28 52.51 14.47 52.08 14.47H52.07ZM52.01 3.6C52.01 4.03 51.92 4.44 51.73 4.82C51.54 5.21 51.29 5.54 50.96 5.82C50.64 6.11 50.26 6.34 49.82 6.51C49.38 6.68 48.93 6.76 48.43 6.76C47.93 6.76 47.47 6.68 47.04 6.51C46.6 6.34 46.23 6.11 45.91 5.82C45.59 5.54 45.33 5.2 45.14 4.82C44.95 4.43 44.86 4.03 44.86 3.6C44.86 3.17 44.95 2.76 45.14 2.38C45.33 1.99 45.58 1.66 45.91 1.38C46.22 1.09 46.6 0.860002 47.04 0.690002C47.48 0.520002 47.94 0.440002 48.43 0.440002C48.92 0.440002 49.38 0.520002 49.82 0.690002C50.26 0.860002 50.63 1.09 50.96 1.38C51.28 1.66 51.54 2 51.73 2.38C51.92 2.77 52.01 3.17 52.01 3.6ZM50.62 3.6C50.62 3.33 50.56 3.07 50.45 2.82C50.34 2.58 50.17 2.36 49.97 2.17C49.77 1.99 49.54 1.85 49.28 1.75C49.02 1.65 48.74 1.59 48.43 1.59C48.12 1.59 47.85 1.64 47.58 1.75C47.32 1.85 47.08 2 46.88 2.17C46.68 2.36 46.53 2.57 46.42 2.82C46.31 3.07 46.26 3.32 46.26 3.6C46.26 3.88 46.31 4.13 46.42 4.37C46.53 4.62 46.68 4.83 46.88 5.02C47.09 5.2 47.32 5.34 47.58 5.45C47.84 5.56 48.12 5.61 48.43 5.61C48.74 5.61 49 5.56 49.27 5.45C49.54 5.34 49.77 5.2 49.97 5.02C50.18 4.83 50.34 4.62 50.45 4.37C50.57 4.13 50.62 3.87 50.62 3.6Z'
        fill='#ffffff'
      />
    </svg>
  );
};

export default LogoText;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Paging from '../util/Paging';
import styles from './Report.module.css';
import {
  getReportList,
  deleteReport,
  getReportListNickname,
} from '../../network/api/ReportAPI';
import ImageDialog from '../util/ImageDialog';
import ExpirationSettingDialog from './ExpirationSettingDialog';

const Report = () => {
  const navigate = useNavigate();
  const [reportList, setReportList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isSettingDialogOpen, setIsSettingDialogOpen] = useState(false);

  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getReportListInit();
  }, [intPage]);

  const getReportListInit = async () => {
    const response = await getReportList({
      page: intPage,
      size: 10,
    });
    if (response) {
      setReportList(response.content);
      setTotalCount(response.totalElements);
    }
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick();
    }
  };

  const searchClick = async () => {
    intPage = 1;
    if (searchValue !== '') {
      const response = await getReportListNickname({
        page: intPage,
        size: 10,
        nickname: searchValue,
      });
      if (response) {
        if (response === undefined) {
          return;
        }
        try {
          setReportList(response.content);
          setTotalCount(response.totalElements);
        } catch (error) {
          console.error('데이터 불러오기 실패:', error);
        }
      }
    } else {
      getReportListInit();
    }
  };

  const clickOpenDialog = (imageUrl) => {
    setImageUrl(imageUrl);
    setIsImageDialogOpen(true);
  };

  const clickDeleteDialog = (id) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      deleteVisitPlaceFunc(id);
    } else {
      return;
    }
  };

  const deleteVisitPlaceFunc = async (id) => {
    const response = await deleteReport({ id: id });
    if (response === 'success') {
      alert('삭제완료');
      window.location.reload(true);
    } else {
      alert('삭제실패');
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/report/${page}`);
  };

  const dateChanger = (date) => {
    if (date !== null) {
      const dateObject = new Date(date);

      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
      const day = dateObject.getDate().toString().padStart(2, '0');
      const hours = dateObject.getHours().toString().padStart(2, '0');
      const minutes = dateObject.getMinutes().toString().padStart(2, '0');
      const seconds = dateObject.getSeconds().toString().padStart(2, '0');
      return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
    }
  };

  return (
    <div className={styles.wrapper}>
      {isImageDialogOpen && imageUrl !== null ? (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrl={imageUrl}
        />
      ) : null}
      {isSettingDialogOpen ? (
        <ExpirationSettingDialog
          setIsSettingDialogOpen={setIsSettingDialogOpen}
        />
      ) : null}
      <div className={styles.pageTitle}>제보 관리</div>
      <div className={styles.aboveContainer}>
        <div>
          <input
            type='text'
            className={styles.searchInput}
            placeholder='제보자 닉네임 입력'
            onChange={searchInputHandler}
            onKeyDown={handleKeyDown}
            value={searchValue}
          />
        </div>
        <div
          className={styles.searchButton}
          onClick={() => {
            searchClick();
          }}>
          검색
        </div>
        <div
          className={styles.searchButton}
          onClick={() => {
            setIsSettingDialogOpen(true);
          }}>
          제보된 핀 유효기한 설정
        </div>
      </div>
      <div className={styles.listContainer}>
        <div className={styles.totalCount}>전체 : {totalCount} 건</div>
        <div className={styles.listTitle}>
          <p>제보자 닉네임</p>
          <p>제보 이미지</p>
          <p>도로명주소</p>
          <p>지번주소</p>
          <p>제보시각</p>
        </div>
        {reportList.length === 0 ? (
          <div className={styles.emptyText}>제보내역이 없습니다</div>
        ) : (
          reportList.map((report) => (
            <div key={report.id} className={styles.banner}>
              <p title={report.userNickname}>{report.userNickname}</p>
              <p
                onClick={() => {
                  clickOpenDialog(report.imageUrl);
                }}>
                이미지 보기
              </p>
              <p>{report.roadAddress}</p>
              <p>{report.jibunAddress}</p>
              <p>{dateChanger(report.reportTime)}</p>
              <p
                className={styles.deleteButton}
                onClick={() => clickDeleteDialog(report.id)}>
                삭제
              </p>
            </div>
          ))
        )}
      </div>
      <div className={styles.pagingContainer}>
        <Paging
          page={intPage}
          countPerPage={10}
          pageRangeDisplayed={5}
          count={totalCount}
          setPage={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Report;

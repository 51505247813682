import { baseInstance, authInstance } from '../AxiosClient';

export const getReportExpirationDay = async () => {
  try {
    const { data } = await authInstance.get(`reportExpirationDay/get`);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyReportExpirationDay = async (getReportExpirationDay) => {
  try {
    const { data } = await authInstance.post(
      `reportExpirationDay/modify`,
      getReportExpirationDay
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

import React, { useEffect, useState } from 'react';
import styles from './ExpirationSettingDialog.module.css';
import PropTypes from 'prop-types';
import {
  getReportExpirationDay,
  modifyReportExpirationDay,
} from '../../network/api/ReportExpirationDayAPI';
import CloseButton from '../../resource/images/svg/CloseButton';

const ExpirationSettingDialog = ({ setIsSettingDialogOpen }) => {
  const [expiration, setExpiration] = useState({ id: 1, expirationDay: 0 });
  useEffect(() => {
    getExpirationDayInit();
  }, []);

  const expirationInputHandler = (e) => {
    setExpiration({ id: expiration.id, expirationDay: e.target.value });
  };

  const getExpirationDayInit = async () => {
    const response = await getReportExpirationDay();
    if (response) {
      setExpiration({ id: response.id, expirationDay: response.expirationDay });
    }
  };

  const modifyExpirationDay = async () => {
    const response = await modifyReportExpirationDay({
      id: expiration.id,
      expirationDay: expiration.expirationDay,
    });
    if (response === 'success') {
      alert('수정완료');
      window.location.reload(true);
    } else {
      alert('수정실패');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsSettingDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          <div className={styles.inputTitle}>만료기한</div>
          <div className={styles.inputBox}>
            <input
              type='tel'
              className={styles.inputId}
              placeholder='만료기한'
              maxLength={12}
              onChange={expirationInputHandler}
              value={expiration.expirationDay}
            />
            <div>일</div>
          </div>
          <div
            className={styles.loginButton}
            onClick={() => {
              modifyExpirationDay();
            }}>
            만료기한 수정
          </div>
        </div>
      </div>
    </div>
  );
};

ExpirationSettingDialog.propTypes = {
  setIsSettingDialogOpen: PropTypes.func,
};

export default ExpirationSettingDialog;

import { baseInstance, authInstance } from '../AxiosClient';

export const getEventBanner = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`eventBanner/get`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const registerEventBannerLink = async (eventBanner) => {
  try {
    const { data } = await authInstance.post(
      `eventBanner/register`,
      eventBanner
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyEventBanner = async (eventBannerInfo) => {
  try {
    const { data } = await authInstance.post(
      `eventBanner/modify`,
      eventBannerInfo
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyEventBannerLink = async (eventBannerLink) => {
  try {
    const { data } = await authInstance.post(
      `eventBanner/modify/link`,
      eventBannerLink
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyEventBannerImage = async (eventBannerImage) => {
  try {
    const { data } = await authInstance.post(
      `eventBanner/modify/image`,
      eventBannerImage
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteEventBanner = async (id) => {
  try {
    const { data } = await authInstance.delete(`eventBanner/delete`, {
      params: id,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

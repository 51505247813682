import React from 'react';
import Dashboard from './components/dash-board/Dashboard';
import EventBanner from './components/event-banner/EventBanner';
import VisitPlace from './components/visit-place/VisitPlace';
import WithTogether from './components/with-together/WithTogether';
import Report from './components/report/Report';
import Notice from './components/notice/Notice';
import EventSchedule from './components/event-schedule/EventSchedule';
import SignIn from './components/auth/signin/SignIn';
import Poo from './components/poo/Poo';
import Place from './components/place/Place';
import User from './components/user/User';
import Dog from './components/dog/Dog';
import Alarm from './components/alarm/Alarm';
import VendingMachine from './components/machine/VendingMachine';
import UserMachineLog from './components/user-machine-log/UserMachineLog';
import UserMachineLogDetail from './components/user-machine-log-detail/UserMachineLogDetail';

const routes = [
  {
    name: 'Sign In',
    key: 'sign-in',
    route: '/authentication/sign-in',
    private: false,
    component: <SignIn />,
  },
  {
    name: 'Dashboard',
    key: 'dashboard',
    route: '/dashboard',
    private: true,
    component: <Dashboard />,
  },
  {
    name: 'EventBanner',
    key: 'eventbanner',
    route: '/event-banner/:page',
    private: true,
    component: <EventBanner />,
  },
  {
    name: 'VisitPlace',
    key: 'visitplace',
    route: '/visit-place/:page',
    private: true,
    component: <VisitPlace />,
  },
  {
    name: 'WithTogether',
    key: 'withtogether',
    route: '/with-together/:page',
    private: true,
    component: <WithTogether />,
  },
  {
    name: 'Report',
    key: 'report',
    route: '/report/:page',
    private: true,
    component: <Report />,
  },
  {
    name: 'Poo',
    key: 'poo',
    route: '/poo/:page',
    private: true,
    component: <Poo />,
  },
  {
    name: 'Place',
    key: 'place',
    route: '/place/:page',
    private: true,
    component: <Place />,
  },
  {
    name: 'Vending Machine',
    key: 'vending-machine',
    route: '/machine/:page',
    private: true,
    component: <VendingMachine />,
  },
  {
    name: 'Collection Box',
    key: 'collection-box',
    route: '/machine-cb/:page',
    private: true,
    component: <VendingMachine category={'수거함'} />,
  },
  {
    name: 'User',
    key: 'user',
    route: '/user/:page',
    private: true,
    component: <User />,
  },
  {
    name: 'Dog',
    key: 'dog',
    route: '/dog/:page',
    private: true,
    component: <Dog />,
  },
  {
    name: 'UserMachineLog',
    key: 'user-machine-log',
    route: '/user-machine-log/:page',
    private: true,
    component: <UserMachineLog />,
  },
  {
    name: 'UserMachineLogDetail',
    key: 'user-machine-log-detail',
    route: '/user-machine-log-detail/:id',
    private: true,
    component: <UserMachineLogDetail />,
  },
  {
    name: 'Notice',
    key: 'notice',
    route: '/notice/:page',
    private: true,
    component: <Notice />,
  },
  {
    name: 'EventSchedule',
    key: 'eventschedule',
    route: '/event-schedule/:page',
    private: true,
    component: <EventSchedule />,
  },
  {
    name: 'Alarm',
    key: 'alarm',
    route: '/alarm/:page',
    private: true,
    component: <Alarm />,
  },
];

export default routes;

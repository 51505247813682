import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './VisitPlaceModifyDialog.module.css';
import PropTypes from 'prop-types';
import {
  modifyVisitPlace,
  modifyVisitPlaceWithImage,
} from '../../network/api/VisitPlaceAPI';
import TextareaAutosize from 'react-textarea-autosize';
import CloseButton from '../../resource/images/svg/CloseButton';
import { compressImage } from '../../utils/compressImage';

let imageFileBlob = null;

const VisitPlaceModifyDialog = ({
  setIsModifyDialogOpen,
  visitPlace,
  intPage,
}) => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [linkUrl, setLinkLink] = useState(visitPlace.linkUrl);
  const [image, setImage] = useState(visitPlace.imageUrl);
  const [location, setLocation] = useState(visitPlace.location);
  const handleInputChange = (value) => {
    const processedText = value
      .replace(/<\/p>/g, '\n')
      .replace(/<\/?p>/g, '')
      .replace(/\n$/, '');
    return processedText;
  };
  const [inputNotice, setInputNotice] = useState(
    handleInputChange(visitPlace.notice)
  );
  const [outputNotice, setOutputNotice] = useState(visitPlace.notice);
  const [noticeTextCount, setNoticeTextCount] = useState(inputNotice.length);

  useEffect(() => {
    return () => {
      imageFileBlob = null;
    };
  }, []);

  const locationInputHandler = (e) => {
    setLocation(e.target.value);
  };

  const noticeInputHandler = (e) => {
    const currentNewlines = (e.target.value.match(/\n/g) || []).length;

    if (currentNewlines < 2) {
      setNoticeTextCount(
        e.target.value.substring(0, 500).replace(/<br\s*\/?>/gm, '\n').length
      );
      const newText = e.target.value;
      setInputNotice(newText.substring(0, 500));

      const paragraphs = newText.split('\n').map((line) => `<p>${line}</p>`);
      const resultString = paragraphs.join('');
      setOutputNotice(resultString);
    }
  };

  const linkUrlInputHandler = (e) => {
    setLinkLink(e.target.value);
  };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        alert('파일 크기는 20MB 이하여야 합니다.');
      } else {
        const compressedImage = await compressImage(file);
        const fileURL = URL.createObjectURL(compressedImage);
        imageFileBlob = compressedImage;
        setImage(fileURL);
      }
    }
    event.target.value = '';
  };

  const openCamera = () => inputRef.current && inputRef.current.click();

  const modifyButtonClick = async () => {
    if (
      visitPlace.linkUrl === linkUrl &&
      visitPlace.imageUrl === image &&
      visitPlace.notice === outputNotice &&
      visitPlace.location === location
    ) {
      return;
    } else if (visitPlace.imageUrl !== image) {
      const formData = new FormData();
      formData.append('multipartFile', imageFileBlob);
      const uploaderString = JSON.stringify({
        id: visitPlace.id,
        location: location,
        notice: outputNotice,
        linkUrl: linkUrl,
      });
      formData.append(
        'visitPlace',
        new Blob([uploaderString], { type: 'application/json' })
      );

      const response = await modifyVisitPlaceWithImage(formData);
      if (response === 'success') {
        window.location.reload(true);
      } else {
        alert('수정실패');
      }
    } else {
      const response = await modifyVisitPlace({
        id: visitPlace.id,
        location: location,
        notice: outputNotice,
        linkUrl: linkUrl,
      });
      if (response === 'success') {
        alert('수정완료');
        window.location.reload(true);
      } else {
        alert('수정실패');
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsModifyDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          <div className={styles.inputTitle}>
            위치 (한글 기준 17자, 공백은 0.5자 취급)
          </div>
          <input
            type='text'
            maxLength={50}
            className={styles.inputId}
            placeholder='위치'
            onChange={locationInputHandler}
            value={location}
          />
          <div className={styles.inputTitle}>
            안내문구 (한줄당 한글 기준 14자, 공백은 0.5자 취급, 두줄까지
            작성가능)
          </div>
          <TextareaAutosize
            maxLength={10000}
            className={styles.quillBox}
            value={inputNotice}
            placeholder={'안내문구를 입력하세요'}
            onChange={noticeInputHandler}
          />
          <div>{noticeTextCount}/500</div>
          <div className={styles.inputTitle}>링크</div>
          <input
            type='text'
            className={styles.inputId}
            placeholder='링크'
            onChange={linkUrlInputHandler}
            value={linkUrl}
          />
          <div className={styles.inputTitle}>이미지</div>
          <div className={styles.imageContainer}>
            {image !== null && image !== '' ? (
              <>
                <input
                  id='input-store-image-upload'
                  type='file'
                  ref={inputRef}
                  accept='.jpg, .jpeg, .png'
                  onChange={handleFileInputChange}
                  style={{
                    position: 'absolute',
                    left: '-1000px',
                    visibility: 'hidden',
                  }}
                />
                <img
                  className={styles.afterImageContainer}
                  onClick={openCamera}
                  src={image}
                />
              </>
            ) : (
              <>
                <input
                  id='input-store-image-upload'
                  type='file'
                  ref={inputRef}
                  accept='.jpg, .jpeg, .png'
                  onChange={handleFileInputChange}
                  style={{
                    position: 'absolute',
                    left: '-1000px',
                    visibility: 'hidden',
                  }}
                />
                <label
                  htmlFor='input-store-image-upload'
                  className={styles.imageUploadButton}>
                  <img
                    className={styles.profileBox}
                    src={visitPlace.imageUrl}
                  />
                </label>
              </>
            )}
          </div>
          <div
            className={styles.loginButton}
            onClick={() => {
              modifyButtonClick();
            }}>
            변경하기
          </div>
        </div>
      </div>
    </div>
  );
};

VisitPlaceModifyDialog.propTypes = {
  setIsModifyDialogOpen: PropTypes.func,
  visitPlace: PropTypes.object,
  intPage: PropTypes.number,
};

export default VisitPlaceModifyDialog;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import {
  getPlace,
  deletePlace,
  getPlaceByCategory,
  getPlaceByName,
  getPlaceByNameCategory,
} from '../../network/api/PlaceAPI';
import styles from './Place.module.css';
import ImageDialog from '../util/ImageDialog';
import Paging from '../util/Paging';
import PlaceRegisterDialog from './PlaceRegisterDialog';
import PlaceModifyDialog from './PlaceModifyDialog';

const Place = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [categoryList, setCategoryList] = useState([
    {
      category: '호텔',
      isSelected: false,
    },
    {
      category: '공원',
      isSelected: false,
    },
    {
      category: '놀이터',
      isSelected: false,
    },
    {
      category: '미용',
      isSelected: false,
    },
    {
      category: '병원',
      isSelected: false,
    },
    {
      category: '쇼핑',
      isSelected: false,
    },
    {
      category: '숙박',
      isSelected: false,
    },
    {
      category: '식당',
      isSelected: false,
    },
    {
      category: '카페',
      isSelected: false,
    },
    {
      category: '캠핑',
      isSelected: false,
    },
    {
      category: '기타',
      isSelected: false,
    },
  ]);
  const [category, setCategory] = useState(
    location.state !== null ? location.state : ''
  );
  const [placeList, setPlaceList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [imageUrlList, setImageUrlList] = useState(null);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [place, setPlace] = useState(null);
  const [isModifyDialogOpen, setIsModifyDialogOpen] = useState(false);
  const [isRegisterPlaceDialogOpen, setIsRegisterPlaceDialogOpen] =
    useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { page } = useParams();
  let intPage = parseInt(page);

  useEffect(() => {
    getPlaceInit();
  }, [intPage, categoryList]);

  const getPlaceInit = async () => {
    if (allItemsUnselected()) {
      if (searchValue !== '') {
        const response = await getPlaceByName({
          page: intPage,
          size: 10,
          name: searchValue,
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setPlaceList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      } else {
        const response = await getPlace({
          page: intPage,
          size: 10,
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setPlaceList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      }
    } else {
      let categoryList = checkCategory();
      if (searchValue !== '') {
        const response = await getPlaceByNameCategory({
          page: intPage,
          size: 10,
          name: searchValue,
          categoryList: categoryList.join(', '),
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setPlaceList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      } else {
        const response = await getPlaceByCategory({
          page: intPage,
          size: 10,
          categoryList: categoryList.join(', '),
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            setPlaceList(response.content);
            setTotalCount(response.totalElements);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      }
    }
  };

  const searchInputHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick();
    }
  };

  const searchClick = async () => {
    intPage = 1;
    getPlaceInit();
  };

  const allItemsUnselected = () => {
    return categoryList.every((item) => !item.isSelected);
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const menu = categoryList.map((categoryItem, index) => (
    <div
      key={index}
      className={
        categoryItem.isSelected ? styles.slectedMenuItem : styles.menuItem
      }
      onClick={() => {
        handleToggleCategoryList(categoryItem);
      }}>
      {categoryItem.category}
    </div>
  ));

  const handleToggleCategoryList = (categoryItem) => {
    if (intPage === 1) {
      setCategoryList(
        categoryList.map((item) => {
          if (item.category === categoryItem.category) {
            return { ...item, isSelected: !item.isSelected };
          } else {
            return item;
          }
        })
      );
      intPage = 1;
      if (category === categoryItem.category) {
        setCategory('');
      } else {
        setCategory(categoryItem.category);
      }
    } else {
      navigate(`/place/${1}`, { state: categoryItem.category });
      setCategoryList(
        categoryList.map((item) => {
          if (item.category === categoryItem.category) {
            return { ...item, isSelected: !item.isSelected };
          } else {
            return item;
          }
        })
      );
    }
  };

  const checkCategory = () => {
    let tempCategoryList = [];
    for (const item of categoryList) {
      if (item.isSelected) {
        tempCategoryList.push(item.category);
      }
    }
    return tempCategoryList;
  };

  const clickOpenDialog = (imageUrlList) => {
    setImageUrlList(imageUrlList);
    setIsImageDialogOpen(true);
  };

  const clickDeleteDialog = (id) => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      deletePlaceFunc(id);
    } else {
      return;
    }
  };

  const deletePlaceFunc = async (id) => {
    const response = await deletePlace({ id: id });
    if (response === 'success') {
      alert('삭제완료');
      window.location.reload(true);
    } else {
      alert('삭제실패');
    }
  };

  const handlePageChange = async (page) => {
    navigate(`/place/${page}`, { state: category });
  };

  const clickModifyDialog = (place) => {
    setPlace(place);
    setIsModifyDialogOpen(true);
  };

  const dateChanger = (date) => {
    const dateObject = new Date(date);

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getSeconds().toString().padStart(2, '0');
    return `${year}년 ${month}월 ${day}일 ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className={styles.wrapper}>
      {isImageDialogOpen && imageUrlList !== null ? (
        <ImageDialog
          setIsImageDialogOpen={setIsImageDialogOpen}
          imageUrlList={imageUrlList}
        />
      ) : null}
      {isRegisterPlaceDialogOpen ? (
        <PlaceRegisterDialog
          setIsRegisterPlaceDialogOpen={setIsRegisterPlaceDialogOpen}
        />
      ) : null}
      {isModifyDialogOpen ? (
        <PlaceModifyDialog
          place={place}
          setIsModifyDialogOpen={setIsModifyDialogOpen}
        />
      ) : null}
      <div className={styles.innerWrapper}>
        <div className={styles.pageTitle}>장소 관리</div>
        <div className={styles.listContainer}>
          <div className={styles.aboveContainer}>
            <ScrollMenu onWheel={onWheel}>{menu}</ScrollMenu>
            <input
              type='text'
              className={styles.searchInput}
              placeholder='장소이름 입력'
              onChange={searchInputHandler}
              onKeyDown={handleKeyDown}
              value={searchValue}
            />
            <div
              className={styles.searchButton}
              onClick={() => {
                searchClick();
              }}>
              검색
            </div>
          </div>
          <div className={styles.totalCount}>전체 : {totalCount} 건</div>
          <div className={styles.listTitle}>
            <p>카테고리</p>
            <p>장소이름</p>
            <p>장소 이미지</p>
            <p>등록일</p>
            <p
              onClick={() => {
                setIsRegisterPlaceDialogOpen(true);
              }}>
              추가하기
            </p>
          </div>
          {placeList.length === 0 ? (
            <div className={styles.emptyText}>장소내역이 없습니다</div>
          ) : (
            placeList.map((place) => (
              <div key={place.id} className={styles.banner}>
                <p>{place.category}</p>
                <p>{place.name}</p>
                <p
                  onClick={() => {
                    clickOpenDialog(place.imageUrlList);
                  }}>
                  이미지 보기
                </p>
                <p>{dateChanger(place.registerDate)}</p>
                <p
                  className={styles.modifyButton}
                  onClick={() => {
                    clickModifyDialog(place);
                  }}>
                  수정
                </p>
                <p
                  className={styles.deleteButton}
                  onClick={() => clickDeleteDialog(place.id)}>
                  삭제
                </p>
              </div>
            ))
          )}
          <div className={styles.pagingContainer}>
            <Paging
              page={intPage}
              countPerPage={10}
              pageRangeDisplayed={5}
              count={totalCount}
              setPage={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Place;

import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './LoadingComponenet.module.css';
import loading from '../../resource/images/loading.gif';

const LoadingComponent = () => {
  const location = useLocation();

  const checkRoute = () => {
    if (
      location.pathname === '/home' ||
      location.pathname === '/map' ||
      location.pathname === '/report-map' ||
      location.pathname === '/event-schedule' ||
      location.pathname === '/mypage'
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className={checkRoute() ? styles.navLoading : styles.loading}>
      <img className={styles.image} src={loading} alt='로딩 중' />
    </div>
  );
};

export default LoadingComponent;

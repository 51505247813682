import { baseInstance, authInstance } from '../AxiosClient';

export const registerPlace = async (place) => {
  try {
    const { data } = await authInstance.post(`place/register`, place);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyPlace = async (place) => {
  try {
    const { data } = await authInstance.post(`place/modify`, place);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getPlace = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`place/get`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getPlaceByCategory = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`place/get/place/category`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getPlaceByName = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`place/get/place/name`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getPlaceByNameCategory = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`place/get/place/name/category`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getRestRoom = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`place/get/restroom`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getRestRoomByName = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`place/get/restroom/name`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getRestRoomByCategory = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`place/get/restroom/category`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getRestRoomByNameCategory = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(
      `place/get/restroom/name/category`,
      {
        params: pageInfo,
      }
    );
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deletePlace = async (id) => {
  try {
    const { data } = await authInstance.delete(`place/delete`, {
      params: id,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

import { baseInstance, authInstance } from '../AxiosClient';

export const getNoticeList = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`notice/get`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getNoticeMain = async (pageInfo) => {
  try {
    const { data } = await authInstance.get(`notice/get/main`, {
      params: pageInfo,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const registerNotice = async (notice) => {
  try {
    const { data } = await authInstance.post(`notice/register`, notice);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const modifyNotice = async (notice) => {
  try {
    const { data } = await authInstance.post(`notice/modify`, notice);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const deleteNotice = async (id) => {
  try {
    const { data } = await authInstance.delete(`notice/delete`, {
      params: id,
    });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

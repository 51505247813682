import React, { useState, useRef } from 'react';
import styles from './NoticeRegisterDialog.module.css';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import { registerNotice } from '../../network/api/NoticeAPI';
import CloseButton from '../../resource/images/svg/CloseButton';

const NoticeRegisterDialog = ({ setIsRegisterDialogOpen }) => {
  const inputTextRef = useRef(null);
  const [isMain, setIsMain] = useState(false);
  const [title, setTitle] = useState('');
  const [inputNotice, setInputNotice] = useState('');
  const [outputNotice, setOutputNotice] = useState('');
  const [noticeTextCount, setNoticeTextCount] = useState(0);

  const titleInputHandler = (e) => {
    setTitle(e.target.value);
  };

  const noticeInputHandler = (e) => {
    setNoticeTextCount(
      e.target.value.substring(0, 10000).replace(/<br\s*\/?>/gm, '\n').length
    );
    const newText = e.target.value;
    setInputNotice(newText.substring(0, 10000));

    const paragraphs = newText.split('\n').map((line) => `<p>${line}</p>`);
    const resultString = paragraphs.join('');
    const final = convertToBrString(resultString);
    setOutputNotice(final);
  };

  const convertToBrString = (inputString) => {
    const regex = /(<p><\/p>)+/g;

    const resultString = inputString.replace(regex, (match) => {
      const blockCount = match.length / 7;
      if (blockCount > 1) {
        const brTags = Array(blockCount - 1)
          .fill('<br>')
          .join('');
        return `<p>${brTags}</p>`;
      } else {
        return match;
      }
    });

    return resultString;
  };

  const changeMinHeight = (height) => {
    if (inputTextRef.current) {
      if (height < 55) {
        inputTextRef.current.style.minHeight = '55px';
      } else {
        inputTextRef.current.style.minHeight = `${height}px`;
      }
    }
  };

  const registerButtonClick = async () => {
    if (title !== '' && outputNotice !== '') {
      const response = await registerNotice({
        title: title,
        content: outputNotice,
        main: isMain,
      });
      if (response === 'success') {
        alert('등록완료');
        window.location.reload(true);
      } else if (response === 'isMain') {
        alert(
          '이미 등록된 메인공지가 있습니다. 등록하시려면 이미 등록된 메인공지 옵션을 해제해 주세요'
        );
      } else {
        alert('등록실패');
      }
    } else {
      alert('입력항목들을 모두 입력해 주세요 ');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsRegisterDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          <div className={styles.inputTopTitle}>홈 메인 공지 여부</div>
          <div className={styles.genderRadioGroup}>
            <div
              className={styles.radioBox}
              onClick={() => {
                setIsMain(!isMain);
              }}>
              {isMain ? (
                <div className={styles.selectedRadio}>
                  <div className={styles.selectedRadioInner} />
                </div>
              ) : (
                <div className={styles.unselectedRadio} />
              )}
              <div className={styles.radioText}>등록</div>
            </div>
          </div>
          <div className={styles.inputTitle}>공지 제목 (최대 100자)</div>
          <input
            type='text'
            maxLength={100}
            className={styles.inputId}
            placeholder='공지 제목'
            onChange={titleInputHandler}
            value={title}
          />
          <div className={styles.inputTitle}>본문내용 (최대 10,000자)</div>
          <TextareaAutosize
            maxLength={10000}
            ref={inputTextRef}
            cacheMeasurements
            onHeightChange={(height) => {
              changeMinHeight(height);
            }}
            className={styles.quillBox}
            value={inputNotice}
            placeholder={'공지사항을 입력하세요'}
            onChange={noticeInputHandler}
          />
          <div>{noticeTextCount}/10000</div>
          <div
            className={styles.loginButton}
            onClick={() => {
              registerButtonClick();
            }}>
            등록하기
          </div>
        </div>
      </div>
    </div>
  );
};

NoticeRegisterDialog.propTypes = {
  setIsRegisterDialogOpen: PropTypes.func,
};

export default NoticeRegisterDialog;

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ADD_DOG = 'ADD_DOG';
export const UPDATE_DOG = 'UPDATE_DOG';
export const UPDATE_USERNAME = 'UPDATE_USERNAME';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_NICKNAME = 'UPDATE_NICKNAME';
export const UPDATE_PHONE = 'ADD_DUPDATE_PHONEOG';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const login = (user) => {
  return {
    type: LOGIN,
    payload: user,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const addDog = (dog) => {
  return {
    type: ADD_DOG,
    payload: dog,
  };
};

export const updateDog = (dog) => {
  return {
    type: UPDATE_DOG,
    payload: dog,
  };
};

export const updateUsername = (username) => {
  return {
    type: UPDATE_USERNAME,
    payload: username,
  };
};

export const updateName = (name) => {
  return {
    type: UPDATE_NAME,
    payload: name,
  };
};

export const updateNickname = (nickname) => {
  return {
    type: UPDATE_NICKNAME,
    payload: nickname,
  };
};

export const updatePhone = (phone) => {
  return {
    type: UPDATE_PHONE,
    payload: phone,
  };
};

export const updateProfile = (profile) => {
  return {
    type: UPDATE_PROFILE,
    payload: profile,
  };
};

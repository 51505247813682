import React, { useEffect, useState, useRef } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import styles from './SelectEventScheduleDialog.module.css';
import {
  getEventSchedule,
  getEventScheduleByCategory,
} from '../../network/api/EventScheduleAPI';
import { registerParticipateEvent } from '../../network/api/WithTogetherAPI';
import CloseButton from '../../resource/images/svg/CloseButton';

let scrollLoadLock = false;

const SelectEventScheduleDialog = ({
  category,
  participateList,
  setIsRegisterParticipatePlaceDialogOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  let idList = [];
  for (const item of participateList) {
    idList.push(item.id);
  }
  const [selectedIdList, setSelectedIdList] = useState(idList);
  const [categoryList, setCategoryList] = useState([
    {
      category: '행사',
      isSelected: false,
    },
    {
      category: '교육',
      isSelected: false,
    },
    {
      category: '축제',
      isSelected: false,
    },
  ]);
  const [eventList, setEventList] = useState([]);

  const { ref, inView } = useInView();

  useEffect(() => {
    scrollLoadLock = false;
  }, []);

  useEffect(() => {
    if (inView && !isLoading) {
      searchEvent(true, false);
    }
  }, [inView]);

  useEffect(() => {
    setEventList([]);
    scrollLoadLock = false;
    searchEvent(false, true);
  }, [categoryList]);

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const menu = categoryList.map((categoryItem, index) => (
    <div
      key={index}
      className={
        categoryItem.isSelected ? styles.slectedMenuItem : styles.menuItem
      }
      onClick={() => {
        handleToggleCategoryList(categoryItem);
      }}>
      {categoryItem.category}
    </div>
  ));

  const handleToggleCategoryList = (categoryItem) => {
    setCategoryList(
      categoryList.map((item) => {
        if (item.category === categoryItem.category) {
          return { ...item, isSelected: !item.isSelected };
        } else {
          return item;
        }
      })
    );
  };

  const searchEvent = async (isScrolled, isFirst) => {
    if (!scrollLoadLock) {
      setIsLoading(true);
      if (allItemsUnselected()) {
        const response = await getEventSchedule({
          page: isFirst ? 1 : page,
          size: 10,
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            if (response.last) {
              scrollLoadLock = true;
            }
            if (isScrolled) {
              setEventList([...eventList, ...response.content]);
            } else {
              setEventList(response.content);
            }
            setPage(isFirst ? 2 : page + 1);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      } else {
        let categoryList = checkCategory();
        const response = await getEventScheduleByCategory({
          page: isFirst ? 1 : page,
          size: 10,
          categoryList: categoryList.join(', '),
        });
        if (response) {
          if (response === undefined) {
            return;
          }
          try {
            if (response.last) {
              scrollLoadLock = true;
            }
            if (isScrolled) {
              setEventList([...eventList, ...response.content]);
            } else {
              setEventList(response.content);
            }
            setPage(isFirst ? 2 : page + 1);
          } catch (error) {
            console.error('데이터 불러오기 실패:', error);
          }
        }
      }
      setIsLoading(false);
    }
  };

  const allItemsUnselected = () => {
    return categoryList.every((item) => !item.isSelected);
  };

  const checkCategory = () => {
    let tempCategoryList = [];
    for (const item of categoryList) {
      if (item.isSelected) {
        tempCategoryList.push(item.category);
      }
    }
    return tempCategoryList;
  };

  const checkButton = (id) => {
    if (!idList.includes(id)) {
      const itemIndex = selectedIdList.findIndex((item) => item === id);

      if (itemIndex !== -1) {
        const newList = [...selectedIdList];
        newList.splice(itemIndex, 1);
        setSelectedIdList(newList);
      } else {
        setSelectedIdList([...selectedIdList, id]);
      }
    }
  };

  const isDuplicateId = (idToCheck) => {
    return selectedIdList.some((item) => item === idToCheck);
  };

  const registerButtonClick = async () => {
    const response = await registerParticipateEvent({
      category: category,
      idList: selectedIdList,
    });

    if (response === 'success') {
      window.location.reload(true);
    } else {
      alert('추가실패');
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <div
            className={styles.dialogCloseButton}
            onClick={() => {
              setIsRegisterParticipatePlaceDialogOpen(false);
            }}>
            <CloseButton />
          </div>
          <div className={styles.dialogContainer}>
            <div className={styles.inputTopTitle}>
              현재 카테고리: {category}
            </div>
            <div className={styles.aboveContainer}>
              <ScrollMenu onWheel={onWheel}>{menu}</ScrollMenu>
            </div>
            <div className={styles.eventCardContainer}>
              <ul>
                {eventList.length === 0 ? (
                  <div className={styles.emptyText}>
                    행사·교육 일정이 없습니다
                  </div>
                ) : (
                  <>
                    {eventList.map((event) => (
                      <li key={event.id} className={styles.rowBox}>
                        <div>{event.name}</div>
                        <div
                          className={styles.radioBox}
                          onClick={() => {
                            checkButton(event.id);
                          }}>
                          {isDuplicateId(event.id) ? (
                            <div className={styles.selectedRadio}>
                              <div className={styles.selectedRadioInner} />
                            </div>
                          ) : (
                            <div className={styles.unselectedRadio} />
                          )}
                          <div className={styles.radioText}>선택</div>
                        </div>
                        <div className={styles.emptySpaceCard} />
                      </li>
                    ))}
                    <li ref={ref} className={styles.emptySpace} />
                  </>
                )}
              </ul>
            </div>
            <div
              className={styles.loginButton}
              onClick={() => {
                registerButtonClick();
              }}>
              추가하기
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SelectEventScheduleDialog.propTypes = {
  category: PropTypes.string,
  participateList: PropTypes.array,
  setIsRegisterParticipatePlaceDialogOpen: PropTypes.func,
};

export default SelectEventScheduleDialog;

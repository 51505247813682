import {
  AUTH_DIALOG_TOGGLE,
  ALERT_DIALOG_TOGGLE,
  ALERT_DIALOG_MESSAGE,
  SET_ROUTE,
} from '../actions/utilActions';

const initialState = {
  isAuthDialogOpen: false,
  isAlertDialogOpen: false,
  alertDialogMessage: '',
  goRoute: '',
};

const utilReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_DIALOG_TOGGLE:
      return {
        ...state,
        isAuthDialogOpen: !state.isAuthDialogOpen,
      };
    case ALERT_DIALOG_TOGGLE:
      return {
        ...state,
        isAlertDialogOpen: !state.isAlertDialogOpen,
      };
    case ALERT_DIALOG_MESSAGE:
      return {
        ...state,
        alertDialogMessage: action.payload,
      };
    case SET_ROUTE:
      return {
        ...state,
        goRoute: action.payload,
      };
    default:
      return state;
  }
};

export default utilReducer;

import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { alertDialogToggle } from '../../store/actions/utilActions';
import styles from './Dashboard.module.css';

const Dashboard = ({
  isAuthenticated,
  isAlertDialogOpen,
  alertDialogToggleBy,
}) => {
  if (!isAuthenticated) {
    return <Navigate to='/authentication/sign-in' />;
  }
  return <div>대시보드</div>;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAlertDialogOpen: state.util.isAlertDialogOpen,
});

const mapDispatchToProps = (dispatch) => ({
  alertDialogToggleBy: () => dispatch(alertDialogToggle()),
});

Dashboard.propTypes = {
  isAuthenticated: PropTypes.bool,
  isAlertDialogOpen: PropTypes.bool,
  alertDialogToggleBy: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import React from 'react';

const CloseButton = () => {
  return (
    <svg
      width='38'
      height='38'
      viewBox='0 0 38 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='19' cy='19' r='19' fill='#E5E5E5' />
      <path
        d='M25.0097 12.9897L12.9893 25.0102'
        stroke='#7F7F7F'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.9894 12.9897L25.0098 25.0102'
        stroke='#7F7F7F'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CloseButton;

import React from 'react';
import styles from './Error.module.css';

const Error = () => {
  const goHome = () => {
    window.location.replace(`${process.env.REACT_APP_M_REDIRECT_URL}`);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.emptySpace} />
      <svg
        className={styles.warningIcon}
        width='73'
        height='65'
        viewBox='0 0 73 65'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M41.0367 2.63514C39.0205 -0.878378 33.9795 -0.878378 31.963 2.63514L0.709664 57.0946C-1.30669 60.6081 1.21373 65 5.24647 65H67.7534C71.7861 65 74.3066 60.6081 72.2904 57.0946L41.0367 2.63514ZM37.4527 25.0577C38.4359 25.0577 39.2255 25.8743 39.1983 26.8633L38.8598 39.1412C38.8336 40.092 38.0597 40.8492 37.1143 40.8492H35.8735C34.9277 40.8492 34.1541 40.092 34.1276 39.1412L33.7892 26.8633C33.7619 25.8743 34.5516 25.0577 35.5347 25.0577H37.4527ZM36.5021 52.8471C35.5756 52.8471 34.7831 52.5214 34.1245 51.87C33.4661 51.2077 33.1424 50.4101 33.1536 49.4784C33.1424 48.5575 33.4661 47.7715 34.1245 47.1201C34.7831 46.4574 35.5756 46.1265 36.5021 46.1265C37.4063 46.1265 38.1875 46.4574 38.8462 47.1201C39.5045 47.7715 39.8395 48.5575 39.8506 49.4784C39.8395 50.096 39.6778 50.6631 39.3652 51.1796C39.0638 51.6848 38.6618 52.0892 38.1596 52.3921C37.6574 52.6953 37.1049 52.8471 36.5021 52.8471Z'
          fill='#FFD12C'
        />
      </svg>

      <div className={styles.firstText}>잘못된 접근입니다.</div>
      <div className={styles.secondText}>오류가 발생했습니다.</div>
      <div className={styles.errorButton} onClick={goHome}>
        홈으로 돌아가기
      </div>
      <div className={styles.emptySpace} />
    </div>
  );
};

export default Error;

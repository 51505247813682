import React, { useEffect, useState } from 'react';
import styles from './ImageDialog.module.css';
import PropTypes from 'prop-types';
import CloseButton from '../../resource/images/svg/CloseButton';

const ImageDialog = ({ setIsImageDialogOpen, imageUrl, imageUrlList }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div
          className={styles.dialogCloseButton}
          onClick={() => {
            setIsImageDialogOpen(false);
          }}>
          <CloseButton />
        </div>
        <div className={styles.dialogContainer}>
          {imageUrlList !== undefined ? (
            imageUrlList.map((resource, index) => (
              <img key={index} className={styles.image} src={resource} />
            ))
          ) : (
            <img className={styles.image} src={imageUrl} />
          )}
        </div>
      </div>
    </div>
  );
};

ImageDialog.propTypes = {
  setIsImageDialogOpen: PropTypes.func,
  imageUrl: PropTypes.string,
  imageUrlList: PropTypes.array,
};

export default ImageDialog;

import axios from 'axios';
import { getAccessToken, signOut } from './api/AuthAPI';

const BASE_URL = `${process.env.REACT_APP_BACKEND}`;

const baseAPI = (url, options) => {
  return axios.create({ baseURL: url, withCredentials: true, ...options });
};

const authAPI = (url, options) => {
  console.log(`${process.env.REACT_APP_BACKEND}`);
  const instance = axios.create({
    baseURL: url,
    withCredentials: true,
    ...options,
  });
  // 요청 인터셉터 설정
  instance.interceptors.request.use(
    (config) => {
      // 여기에서 config에 토큰을 추가하거나 갱신하는 로직을 구현합니다.
      // 예를 들어, localStorage 또는 sessionStorage에서 토큰을 가져올 수 있습니다.
      const accessToken = localStorage.getItem('accessToken');

      // config.headers에 토큰을 설정합니다.
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // 응답 인터셉터 설정
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      // HTTP 상태 코드가 토큰 만료 상태 코드 (예: 401)인 경우에만 토큰 갱신을 시도합니다.
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        switch (error.response.data.message) {
          case '만료된 토큰':
            try {
              // 토큰 갱신
              const newAccessToken = await getAccessToken();
              if (newAccessToken === false) {
                await signOut();
              } else {
                // 갱신된 토큰으로 원래 요청을 재시도합니다.
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                return instance(originalRequest);
              }
            } catch (refreshError) {
              // 토큰 갱신에 실패하면 로그인 화면으로 리디렉션 또는 다른 처리를 수행할 수 있습니다.
              console.error('토큰 갱신에 실패했습니다.', refreshError);
              // 예외 처리 코드 추가
            }
            break;
          case '잘못된 JWT 서명':
          case '지원되지 않는 JWT 토큰':
          case '잘못된 JWT 토큰':
          case '토큰없음':
            await signOut();
            break;
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export const baseInstance = baseAPI(BASE_URL);
export const authInstance = authAPI(BASE_URL);
